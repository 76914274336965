import React, { useState, useEffect } from "react";
import "./Quotations.css";
//notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//api methods
import {
  addQuotation,
  getAllQuotation,
  deleteSingeleQuotation,
} from "../../../API/quotationAPI";

import { getQuotationItems } from "../../../API/quotationItemAPI";

import { getAllCustomer } from "../../../API/customerAPI";
import { getAllProduct } from "../../../API/productAPI";
//
//page/components
import NewQuotation from "./NewQuotation/NewQuotation";
import PDFGenerater from "./PDF/PDF";
//mui
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  gridClasses,
  GridCellParams,
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridSlots,
} from "@mui/x-data-grid";
import { Description, Height } from "@mui/icons-material";

// Define interface for customer object
interface Customer {
  id: number;
  name: string;
  email: string;
  mobile: string;
  createdBy: string;
  updatedBy: string;
}

// Define interface for quotation object
interface Quotation {
  id: number;
  QuotationDate: Date;
  ExpiryDate: Date;
  TotalAmount: Number;
  SalesPerson: string;
  createdBy: string;
  updatedBy: string;
}
//material ui modal style
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  height: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const style2 = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "80%", sm: "70%", md: 700 },
  height: { xs: "auto", md: 500 },
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
//

const today = new Date();
const numberOfDaysToAdd = 3;
const date = today.setDate(today.getDate() + numberOfDaysToAdd);
const defaultValue = new Date(date).toISOString().split("T")[0]; // yyyy-mm-dd

const Quotation = () => {
  // Notifications
  const notifyErr = (msg: any) => toast.error(msg || "Error!");
  const notifyDone = () => toast("Sucess");

  const notify_Error = (message: any) =>
    toast.error(`error! \n ${message}`, {
      position: "top-center",
      theme: "dark",
    });
  const notify_success = (message: any) =>
    toast.success(`Message: \n ${message}`, {
      position: "top-center",
      theme: "colored",
    });
  //
  //New Quotation state variables
  const [NewQuotationCustomer, setNewQuotationCustomer] = useState("");
  const [NewQuotationDate, setNewQuotationDate] = useState("");
  const [NewQuotationExpiryDate, setNewQuotationExpiryDate] = useState("");
  const [NewQuotationTotalPrice, setNewQuotationTotalPrice] = useState(0.0);
  const [NewQuotationUntaxedAmount, setNewQuotationUntaxedAmount] =
    useState(0.0);
  const [NewQuotationTaxAmount, setNewQuotationTaxAmount] = useState(0.0);
  const [NewQuotationTotalStatus, setNewQuotationTotalStatus] = useState("");
  //Edit Quotation state variables
  const [EditQuotationID, SetEditQuotationID] = useState<number>(0);
  const [EditQuotationDate, setEditQuotationDate] = useState("");
  const [EditQuotationExpiryDate, setEditQuotationExpiryDate] = useState("");
  const [EditQuotationUntaxedAmount, setEditQuotationUntaxedAmount] =
    useState("");
  const [EditQuotationTaxedAmount, setEditQuotationTaxedAmount] = useState("");
  const [EditQuotationTotalPrice, setEditQuotationTotalPrice] = useState("");
  const [EditQuotationStatus, setEditQuotationStatus] = useState("");
  const [EditQuotationItems, setEditQuotationItems] = useState<any[]>([]);
  //Customer Data
  const [CustomerID, setCustomerID] = useState("");
  const [CustomerName, setCustomerName] = useState("");
  const [CustomerEmail, setCustomerEmail] = useState("");
  const [CustomerPhone, setCustomerPhone] = useState("");
  //Product Data
  const [ProductID, setProductID] = useState("");
  const [ProductName, setProductName] = useState("");
  const [ProductPrice, setProductPrice] = useState("");
  const [QuotationProductArray, setQuotationProductArray] = useState([]);
  const QuotationProduct: any = [];
  //
  const [QuotationCustomterArray, setQuotationCustomterArray] = useState([]);
  const QuotationCustomter: any = [];
  //
  const [quotationData, setQuotationData] = useState<Quotation[]>([]);
  //mui grid
  const [selectedRows, setSelectedRows] = useState<Quotation[]>([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );
  //mui modal state for New Quotation
  const [openNewQuotation, setopenNewQuotation] = React.useState(false);
  const handleOpenNewQuotation = () => setopenNewQuotation(true);
  const handleCloseNewQuotation = () => setopenNewQuotation(false);
  //mui modal state for New Quotation
  const [openEditQuotation, setopenEditQuotation] = React.useState(false);
  const handleOpenEditQuotation = () => setopenEditQuotation(true);
  const handleCloseEditQuotation = () => setopenEditQuotation(false);
  //
  const [numProducts, setNumProducts] = useState(1);
  const [productsList, setProductsList] = useState([
    {
      ProductID: 0,
      Description: "",
      Quantity: 0,
      UnitPrice: 0.0,
      Tax: 15,
      Subtotal: 0.0,
    },
  ]);
  // const [productsList, setProductsList] = useState(
  //   Array(numProducts).fill({ id: "" })
  // );
  // const [productsListData, setProductsListData] = useState([
  //   {
  //     ProductID: 0,
  //     Quantity: 0,
  //     UnitPrice: 0.0,
  //     Tax: 0.0,
  //     Description: "",
  //     SubTotal: 0.0,
  //   },
  // ]);

  // const handleNumProductsChange = (e: any) => {
  //   setNumProducts(parseInt(e.target.value));
  // };
  //

  //work
  // const handleNumProductsChange = (event: any) => {
  //   const newNumProducts = parseInt(event.target.value, 10);
  //   setNumProducts(newNumProducts);

  //   // Adjust the productsList to match the new number of products
  //   setProductsList((prevProductsList) => {
  //     const updatedList = prevProductsList.slice(0, newNumProducts);
  //     while (updatedList.length < newNumProducts) {
  //       updatedList.push({ id: "" });
  //     }
  //     return updatedList;
  //   });
  //   //

  //   const updatedProductsList = [...productsListData];
  //   if (newNumProducts > productsListData.length) {
  //     for (let i = productsListData.length; i < newNumProducts; i++) {
  //       updatedProductsList.push({
  //         ProductID: 0,
  //         Quantity: 0,
  //         UnitPrice: 0.0,
  //         Tax: 0.0,
  //         Description: "",
  //         SubTotal: 0.0,
  //       });
  //     }
  //   } else {
  //     updatedProductsList.length = newNumProducts;
  //   }
  //   setProductsList(updatedProductsList);
  // };

  const computeUntaxedAmount = async () => {
    let total = 0;
    for (let i = 0; i < productsList.length; i++) {
      // total += productsList[i].Quantity * productsList[i].UnitPrice;
      total += productsList[i].Subtotal;
    }
    setNewQuotationUntaxedAmount(total);
    return total;
  };

  const computeTax = () => {
    let total = 0;
    for (let i = 0; i < productsList.length; i++) {
      total += productsList[i].Quantity * productsList[i].UnitPrice;
    }

    // Assuming the tax rate is 15%
    const taxRate = 0.15;
    const taxAmount = total * taxRate;
    setNewQuotationTaxAmount(taxAmount);
    return taxAmount;
  };

  const computeTotalPrice = () => {
    let subtotal = 0;
    for (let i = 0; i < productsList.length; i++) {
      // total += productsList[i].Quantity * productsList[i].UnitPrice;
      subtotal += productsList[i].Subtotal;
    }

    // Assuming the tax rate is 15%
    const taxRate = 0.15;
    const taxAmount: number = subtotal * taxRate;
    const total = taxAmount + subtotal;
    setNewQuotationTotalPrice(total);
    return total;
  };

  const handleNumProductsChange = async (e: any) => {
    //new
    const num = parseInt(e.target.value, 10);
    setNumProducts(num);
    //

    const updatedProductsList = [...productsList];
    if (num > productsList.length) {
      for (let i = productsList.length; i < num; i++) {
        updatedProductsList.push({
          ProductID: 0,
          Description: "",
          Quantity: 0,
          UnitPrice: 0,
          Tax: 15,
          Subtotal: 0.0,
        });
      }
    } else {
      updatedProductsList.length = num;
    }
    setProductsList(updatedProductsList);
    //
    // setNewQuotationTotalPrice(computeTotalPrice);

    await computeUntaxedAmount();
    await computeTax();
    await computeTotalPrice();
    //
  };

  const handleSelectedProductChange = (index: number, event: any) => {
    //

    //
    const ComputeSubtotal = (price: number, quantity: number, tax: number) => {
      const subtotal = price * quantity;
      // const totalWithTax = subtotal * (1 + tax);
      return subtotal;
    };
    const updatedProductsList = [...productsList];
    const selectedProductId = parseInt(event.target.value, 10); // Convert to number if needed
    const selectedProduct: any = QuotationProductArray.find(
      (product: any) => product.id === selectedProductId
    );
    if (selectedProduct) {
      updatedProductsList[index] = {
        ...updatedProductsList[index],
        ProductID: selectedProduct.id,
        Description: selectedProduct.description,
        UnitPrice: selectedProduct.sales_price, // Assuming you also want to set the price
        Subtotal: ComputeSubtotal(
          selectedProduct.sales_price,
          updatedProductsList[index].Quantity,
          0
        ),
      };
    }
    setProductsList(updatedProductsList);
    //
    computeUntaxedAmount();
    computeTax();
    computeTotalPrice();
    //updatedProductsList[index].ProductID = event.target.value;
    //setProductsList(updatedProductsList);
  };

  // const handleSelectedProductChange = (
  //   index: number,
  //   event: React.ChangeEvent<HTMLSelectElement>
  // ) => {
  //   const updatedProductsList = [...productsListData];
  //   const selectedProductId = parseInt(event.target.value, 10); // Convert to number if needed
  //   const selectedProduct: any = QuotationProductArray.find(
  //     (product: any) => product.id === selectedProductId
  //   );

  //   if (selectedProduct) {
  //     updatedProductsList[index] = {
  //       ...updatedProductsList[index],
  //       ProductID: selectedProduct.id,
  //       Description: selectedProduct.description,
  //       UnitPrice: selectedProduct.sales_price, // Assuming you also want to set the price
  //     };
  //   } else {
  //     updatedProductsList[index] = {
  //       ...updatedProductsList[index],
  //       ProductID: 0,
  //       Description: "",
  //       UnitPrice: 0,
  //     };
  //   }

  //   setProductsListData(updatedProductsList);
  // };

  const handleSelectedQuantityChange = (index: any, event: any) => {
    //

    //
    const ComputeSubtotal = (price: number, quantity: number, tax: number) => {
      const subtotal = price * quantity;
      // const totalWithTax = subtotal * (1 + tax);
      return subtotal;
    };

    // const updatedProductsList = [...productsList];
    // const selectedProductId = parseInt(event.target.value, 10); // Convert to number if needed
    // const selectedProduct: any = QuotationProductArray.find(
    //   (product: any) => product.id === selectedProductId
    // );
    // if (selectedProduct) {
    //   updatedProductsList[index] = {
    //     ...updatedProductsList[index],
    //     ProductID: selectedProduct.id,
    //     Description: selectedProduct.description,
    //     UnitPrice: selectedProduct.sales_price, // Assuming you also want to set the price
    //     Quantity: event.target.value,
    //     Subtotal: ComputeSubtotal(selectedProduct.sales_price, event.target.value, 0),
    //   };
    // }
    // setProductsList(updatedProductsList);

    const updatedProductsList = [...productsList];
    updatedProductsList[index].Quantity = event.target.value;
    updatedProductsList[index].Subtotal = ComputeSubtotal(
      updatedProductsList[index].UnitPrice,
      updatedProductsList[index].Quantity,
      updatedProductsList[index].Tax
    );
    setProductsList(updatedProductsList);
    computeUntaxedAmount();
    computeTax();
    computeTotalPrice();
  };

  const handleSelectedPriceChange = (index: any, event: any) => {
    const updatedProductsList = [...productsList];
    //updatedProductsList[index].price = event.target.value;
    setProductsList(updatedProductsList);
  };

  // Custom functions
  const HandleAddQuotation = async (event: any) => {
    event.preventDefault();
    //console.log("Selected Product List: \n" + JSON.stringify(productsList));
    // console.log(productsList);
    //
    console.log("OriginalData: \n" + JSON.stringify(productsList));
    console.log(Array.isArray(productsList));
    //console.log(productsList[0]);

    // Parse the original data into an array of objects
    //const parsedData = productsList.split(",").map((item) => JSON.parse(item));
    //const ProductListJson = JSON.stringify(productsList);
    // const productListArray = JSON.parse(ProductListJson);
    // const compatibleData = productListArray.map((item:any) => ({
    //   Quantity: parseInt(item.Quantity),
    //   price: parseFloat(item.price),
    //   ProductID: parseInt(item.ProductID),
    // }));
    // console.log("compatibleData" + productListArray);
    try {
      var req = await addQuotation({
        CustomerID: NewQuotationCustomer,
        QuotationItems: productsList.map((item) => ({
          ProductID: item.ProductID,
          Description: item.Description,
          Quantity: item.Quantity,
          UnitPrice: item.UnitPrice,
          Tax: item.Tax,
          Subtotal: item.Subtotal,
        })),
        QuotationDate: NewQuotationDate,
        ExpiryDate: NewQuotationExpiryDate,
        UntaxedAmount: NewQuotationUntaxedAmount,
        TaxAmount: NewQuotationTaxAmount,
        TotalAmount: NewQuotationTotalPrice,
        ProductID: ProductID,
        QuotationStatus: NewQuotationTotalStatus,
      });
      console.log(req);
      notify_success(req.data.msg);
    } catch (err: any) {
      notify_Error(err.response.data);
      console.log(err);
    }
  };

  const GetQutations = async () => {
    try {
      var req: any = await getAllQuotation();
      setQuotationData(req.data);
      console.log("GetQutations" + req.data);
    } catch (err: any) {
      console.log(err);
      //notify_Error(err.response.data);
    }
  };

  useEffect(() => {
    GetQutations();
  }, []);

  const HandleGetCustomers = async (e: any) => {
    e.preventDefault();
    try {
      const call = await getAllCustomer();
      //console.log(call.data[0].id);
      // console.log(call);
      const data: any[] = call.data;
      //console.log(data.length);
      for (let i = 0; i < data.length; i++) {
        QuotationCustomter.push({
          id: data[i].id,
          name: data[i].name,
        });
      }
      //console.log(ProductSupplier);
      setQuotationCustomterArray(QuotationCustomter);
      console.log(QuotationCustomter);
    } catch (err) {
      console.log(err);
    }
  };

  const HandleGetProducts = async (e: any) => {
    e.preventDefault();
    try {
      const callPAPI: any = await getAllProduct();
      //console.log(call.data[0].id);
      // console.log(call);
      const data: any[] = callPAPI.data;
      //console.log(data.length);
      for (let i = 0; i < data.length; i++) {
        QuotationProduct.push({
          id: data[i].id,
          name: data[i].name,
          UnitPrice: data[i].sales_price,
        });
      }
      //console.log(ProductSupplier);
      setQuotationProductArray(callPAPI.data);
      console.log(QuotationProductArray);
    } catch (err) {
      console.log(err);
    }
  };

  const HandlPDFQuotationItems = async (e: any) => {
    e.preventDefault();
    try {
      const callPAPI: any = await getQuotationItems(EditQuotationID);
      //console.log(call.data[0].id);
      console.log(callPAPI);
      const data: any[] = callPAPI.data;
      setEditQuotationItems(data);
      //console.log(data.length);
      // for (let i = 0; i < data.length; i++) {
      //   QuotationProduct.push({
      //     id: data[i].id,
      //     name: data[i].name,
      //     UnitPrice: data[i].sales_price,
      //   });
      // }
      //console.log(ProductSupplier);
      // setQuotationProductArray(callPAPI.data);
      // console.log(QuotationProductArray);
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };

  //MUI Table Methods
  const handleEditClick = (id: GridRowId) => async (event: any) => {
    //setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    //setSupplierID(id);

    await HandleGetCustomers(event);
    await HandleGetProducts(event);
    await HandlPDFQuotationItems(event);
    const editedRow: any = quotationData.find((row) => row.id === id);
    handleOpenEditQuotation();
    SetEditQuotationID(editedRow.id);
    setEditQuotationDate(editedRow.QuotationDate);
    setEditQuotationExpiryDate(editedRow.ExpiryDate);
    setEditQuotationUntaxedAmount(editedRow.UntaxedAmount);
    setEditQuotationTaxedAmount(editedRow.TaxAmount);
    setEditQuotationTotalPrice(editedRow.TotalAmount);
    setEditQuotationStatus(editedRow.Status);
    //set Customer Data
    setCustomerID(editedRow.Customer.id);
    setCustomerName(editedRow.Customer.name);
    setCustomerEmail(editedRow.Customer.email);
    setCustomerPhone(editedRow.Customer.mobile);

    //setSupplierMobile(editedRow.mobile);
    // console.log(editedRow.id);
  };

  const handleSaveClick = (id: GridRowId) => () => {
    //setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => async () => {
    //setRows(rows.filter((row) => row.id !== id));
    //alert("delete");
    try {
      const call = await deleteSingeleQuotation({ id: id });
      console.log(call);
      GetQutations();
      notify_success("");
    } catch (err: any) {
      console.log(err);
      notify_Error(err.response.data);
    }
  };

  const handleCancelClick = (id: GridRowId) => () => {
    // setRowModesModel({
    //   ...rowModesModel,
    //   [id]: { mode: GridRowModes.View, ignoreModifications: true },
    // });
    // const editedRow = rows.find((row) => row.id === id);
    // if (editedRow!.isNew) {
    //   setRows(rows.filter((row) => row.id !== id));
    // }
  };
  //
  //  Table Column defination
  const columns: GridColDef[] = [
    { field: "id", headerName: "Number", width: 70 },
    { field: "QuotationDate", headerName: "Quotation Date", width: 130 },
    { field: "ExpiryDate", headerName: "Expiry Date", width: 130 },
    {
      field: "Customer",
      valueGetter: (value, row) => {
        return `${row.Customer ? row.Customer.name : ""}`;
      },
      headerName: "Customer",
      width: 100,
    },
    { field: "CustomerId", headerName: "CustomerId", width: 130 },

    { field: "SalesPerson", headerName: "Sales Person", width: 130 },
    { field: "Status", headerName: "Status", width: 130 },
    { field: "createdAt", headerName: "Created At", width: 130 },
    { field: "updatedAt", headerName: "Updated At", width: 130 },
    { field: "UntaxedAmount", headerName: "Untaxed Amount", width: 130 },
    { field: "TaxAmount", headerName: "Taxed Amount", width: 130 },
    { field: "TotalAmount", headerName: "Total Amount", width: 130 },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  //
  return (
    <div>
      <ToastContainer />

      <div className="quotationMngActionDiv">
        <Button
          onClick={(event) => {
            handleOpenNewQuotation();
            HandleGetCustomers(event);
            HandleGetProducts(event);
          }}
        >
          New
        </Button>
      </div>
      {/* MUI DATA TABLE */}
      <Box
        sx={{
          height: 300,
          width: "100%",
          [`.${gridClasses.cell}.draft-cell`]: {
            backgroundColor: "lightblue",
          },
          [`.${gridClasses.cell}.pending-approval-cell`]: {
            backgroundColor: "orange",
          },
          [`.${gridClasses.cell}.in-review-cell`]: {
            backgroundColor: "yellow",
          },
          [`.${gridClasses.cell}.approved-cell`]: {
            backgroundColor: "green",
          },
          [`.${gridClasses.cell}.rejected-cell`]: {
            backgroundColor: "red",
          },
          [`.${gridClasses.cell}.pending-customer-response-cell`]: {
            backgroundColor: "purple",
          },
          [`.${gridClasses.cell}.customer-accepted-cell`]: {
            backgroundColor: "lightgreen",
          },
          [`.${gridClasses.cell}.customer-rejected-cell`]: {
            backgroundColor: "darkred",
          },
          [`.${gridClasses.cell}.expired-cell`]: {
            backgroundColor: "gray",
          },
          [`.${gridClasses.cell}.returned-for-edits-cell`]: {
            backgroundColor: "lightgray",
          },
        }}
      >
        <DataGrid
          rows={quotationData}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
            columns: {
              columnVisibilityModel: {
                // Hide columns status and traderName, the other columns will remain visible
                CustomerId: false,
                createdAt: false,
                updatedAt: false,
              },
            },
          }}
          pageSizeOptions={[5, 10]}
          checkboxSelection
          getCellClassName={(params: GridCellParams<any, any, number>) => {
            if (params.field === "Status" || params.value !== null) {
              switch (params.value) {
                case "Draft":
                  return "draft-cell";
                case "Pending Approval":
                  return "pending-approval-cell";
                case "In Review":
                  return "in-review-cell";
                case "Approved":
                  return "approved-cell";
                case "Rejected":
                  return "rejected-cell";
                case "Pending Customer Response":
                  return "pending-customer-response-cell";
                case "Customer Accepted":
                  return "customer-accepted-cell";
                case "Customer Rejected":
                  return "customer-rejected-cell";
                case "Expired":
                  return "expired-cell";
                case "Returned for Edits":
                  return "returned-for-edits-cell";
                default:
                  return "";
              }
              // return "";
            }
            // return params.value >= 15 ? "hot" : "cold";
            return "";
          }}
        />
      </Box>
      {/*  */}
      {/* New Quotation Model */}
      <Modal
        open={openNewQuotation}
        onClose={handleCloseNewQuotation}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {/* <div>
              <button onClick={() => PDFGenerater}>Generate Pdf</button>
            </div> */}
            <h1>New Quotation Form</h1>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div className="quotation_form_div">
              <form onSubmit={HandleAddQuotation} className="quotation_form">
                <div className="form-section">
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label className="form-label">
                        Customer:
                        <select
                          multiple={false}
                          onChange={(event) =>
                            setNewQuotationCustomer(event.target.value)
                          }
                        >
                          <option value="">Select a Customer</option>
                          {QuotationCustomterArray.map((customer: any) => (
                            <option key={customer.id} value={customer.id}>
                              {customer.name}
                            </option>
                          ))}
                        </select>
                      </label>

                      <label className="form-label">
                        Number of Products:
                        <input
                          type="number"
                          value={numProducts}
                          onChange={handleNumProductsChange}
                        />
                      </label>

                      <div className="product-table-div">
                        <label className="form-label">Order Lists</label>
                        <table className="product-table">
                          <tr>
                            <th>Product</th>
                            <th>Description</th>
                            <th>Quantity</th>
                            <th>Unit Price</th>
                            <th>Taxes</th>
                            <th>Subtotal</th>
                          </tr>

                          {/* {productsList.map((pl, index) => (
                          <tr key={index}>
                            <td>
                              <select
                                value={productsListData[index].ProductID}
                                onChange={(event) =>
                                  handleSelectedProductChange(index, event)
                                }
                                required
                              >
                                <option value="">Select a Product</option>
                                {QuotationProductArray.map((product: any) => (
                                  <option key={product.id} value={product.id}>
                                    {product.name}
                                  </option>
                                ))}
                              </select>
                            </td>
                            <td> {productsListData[index].Description}</td>
                          </tr>
                        ))} */}

                          {productsList.map((product, index) => (
                            <tr key={index}>
                              <td>
                                <select
                                  value={product.ProductID}
                                  onChange={(event) =>
                                    handleSelectedProductChange(index, event)
                                  }
                                  required
                                >
                                  <option value="">Select a Product</option>
                                  {QuotationProductArray.map((product: any) => (
                                    <option key={product.id} value={product.id}>
                                      {product.name}
                                    </option>
                                  ))}
                                </select>
                              </td>
                              <td>{productsList[index].Description}</td>
                              <td>
                                <input
                                  type="number"
                                  value={product.Quantity}
                                  placeholder="Unit Quantity"
                                  onChange={(e) =>
                                    handleSelectedQuantityChange(index, e)
                                  }
                                  required
                                />
                              </td>
                              <td>
                                {/* <input
                                type="number"
                                value={product.price}
                                placeholder="Unit Price"
                                onChange={(e) =>
                                  handleSelectedPriceChange(index, e)
                                }
                              /> */}
                                {productsList[index].UnitPrice}
                              </td>

                              <td>{productsList[index].Tax}</td>
                              <td>{productsList[index].Subtotal}</td>
                            </tr>
                          ))}
                        </table>
                      </div>

                      {/* <label className="productlabels">
                      Product:
                      <select
                        multiple={false}
                        onChange={(event) => setProductID(event.target.value)}
                      >
                        <option value="">Select a Product</option>
                        {QuotationProductArray.map((product: any) => (
                          <option key={product.id} value={product.id}>
                            {product.name}
                          </option>
                        ))}
                      </select>
                    </label> */}

                      <label className="form-label">
                        Quotation Date :
                        <input
                          type="date"
                          // defaultValue={defaultValue}
                          onChange={(event) =>
                            setNewQuotationDate(event.target.value)
                          }
                          required
                        ></input>
                      </label>

                      <label className="form-label">
                        Expire Date :
                        <input
                          type="date"
                          placeholder=""
                          onChange={(event) =>
                            setNewQuotationExpiryDate(event.target.value)
                          }
                          required
                        ></input>
                      </label>

                      {/* <label>Total Price : {NewQuotationTotalPrice}</label> */}
                      <div className="QuotaionAmountDiv">
                        <strong>
                          Untaxed Amount: {NewQuotationUntaxedAmount} Birr
                        </strong>
                        <strong>Tax: {NewQuotationTaxAmount} Birr</strong>

                        <strong>Total: {NewQuotationTotalPrice} Birr</strong>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="QuotationActionBtnsDiv">
                  <button
                    className="addbtn"
                    type="submit"
                    onClick={(event) =>
                      setNewQuotationTotalStatus("Pending Approval")
                    }
                  >
                    Subit for Approval
                  </button>

                  <button
                    className="addbtn"
                    type="submit"
                    onClick={(event) => setNewQuotationTotalStatus("Draft")}
                  >
                    Save as Draft
                  </button>
                </div>
              </form>
            </div>
          </Typography>
        </Box>
      </Modal>
      {/*  */}
      {/* Edit Quotation Model */}
      <Modal
        open={openEditQuotation}
        onClose={handleCloseEditQuotation}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style2}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <div>
              <PDFGenerater
                PropsObj={{
                  CompanyName: "Yasart Engineering PLC",
                  CompanyEmail: "info@yasartengineering.com",
                  CompanyPhone: "+251 11 442 0024, +251 11 840 0237",
                  CompanyAddress:
                    "Nefas Silk Lafto Subcity Debrezeit Road \n In front of Nefas Silk Paint Factory, Dawi Bldg II, Ground Floor",
                  CustomerName: CustomerName,
                  CustomerEmail: CustomerEmail,
                  QuotationID: EditQuotationID,
                  QuotationCDate: EditQuotationDate,
                  QuotationEDate: EditQuotationExpiryDate,
                  QuotationStatus: EditQuotationStatus,
                  QuotationItemsList: EditQuotationItems,
                  QuotationUntaxedAmount: EditQuotationUntaxedAmount,
                  QuotationTaxedAmount: EditQuotationTaxedAmount,
                  QuotationTotalPrice: EditQuotationTotalPrice,
                }}
              />
              {EditQuotationStatus == "Approved" && (
                <button className="addbtn">Sended to Customer</button>
              )}
            </div>
            Quotation Edit Form
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div>
              <form>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label>
                        Quotation Date :
                        <input
                          type="date"
                          placeholder=""
                          onChange={(event) =>
                            setEditQuotationDate(event.target.value)
                          }
                          required
                        ></input>
                      </label>

                      <label>
                        Expire Date :
                        <input
                          type="date"
                          placeholder=""
                          onChange={(event) =>
                            setEditQuotationExpiryDate(event.target.value)
                          }
                          required
                        ></input>
                      </label>

                      <label>
                        Total Price :
                        <input
                          type="number"
                          placeholder=""
                          onChange={(event) =>
                            setEditQuotationTotalPrice(event.target.value)
                          }
                          required
                        ></input>
                      </label>

                      {/* <label>
                      Supplier Address :{" "}
                      <textarea
                        onChange={(event) =>
                          setSupplierAddress(event.target.value)
                        }
                        required
                      ></textarea>
                    </label> */}
                    </div>
                  </div>
                </div>
                <button className="addbtn" type="submit">
                  Edit Quotation
                </button>
              </form>
            </div>
          </Typography>
        </Box>
      </Modal>
      {/*  */}
    </div>
  );
};

export default Quotation;
