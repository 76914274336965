import { sessionStorage } from "es-storage";
const rbacArray: { UserModules: any } = sessionStorage.getObject("user_rbac");

const Authorization = (
  application: string,
  obtained: string,
  required: string[]
): boolean => {
  // if (application == "Discuss" && obtained == "AccessDenied") {
  //   return false;
  // } else

  if (application === "Discuss" && required.includes(obtained)) {
    return true;
  }

  if (application === "Dashboard" && required.includes(obtained)) {
    return true;
  }

  if (application === "Employee" && required.includes(obtained)) {
    return true;
  }

  if (application === "Inventory" && required.includes(obtained)) {
    return true;
  }

  if (application === "Customer" && required.includes(obtained)) {
    return true;
  }

  if (application === "Support" && required.includes(obtained)) {
    return true;
  }

  if (application === "Sales" && required.includes(obtained)) {
    return true;
  } else {
    return false;
  }
};

const CheckAdminAccess = () => {
  // console.log("UserID=" + sessionStorage.get("user_id"));
  // console.log(Array.isArray(UserRbac.UserModules)); // Should return true
  // console.log(typeof UserRbac.UserModules); // Should return 'object'

  // const permission = UserRbac.UserModules.find(
  //   (p: { ModuleId: number }) => p.ModuleId === ModuleID
  // );
  if (sessionStorage.get("user_type") === "Administrator") {
    return true;
  } else {
    return false;
  }
};

const CheckUserModuleAccess = (ModuleID: number) => {
  //console.log("UserID=" + sessionStorage.get("user_id"));
  //console.log(Array.isArray(UserRbac.UserModules)); // Should return true
  //console.log(typeof UserRbac.UserModules); // Should return 'object'
  //console.log(UserRbac);

  //const UserRoles = rbacArray.UserRoles;
  // console.log(UserRoles);
  const UserModules = rbacArray.UserModules;
  console.log(UserModules);

  const moduleAccess = rbacArray.UserModules.find(
    (p: { ModuleId: number }) => p.ModuleId === ModuleID
  );
  console.log(moduleAccess);
  if (moduleAccess) {
    if (moduleAccess.Role.Permissions[0].read) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

// const CheckUserModuleAccess = (
//   UserRbac: any,
//   ModuleID: number,
//   userID: number
// ) => {
//   console.log("UserID=" + sessionStorage.get("user_id"));
//   console.log(Array.isArray(UserRbac.UserModules)); // Should return true
//   console.log(typeof UserRbac.UserModules); // Should return 'object'

//   const permission = UserRbac.UserModules.find(
//     (p: { ModuleId: number }) => p.ModuleId === ModuleID
//   );
//   if (permission) {
//     return true;
//   } else {
//     return false;
//   }
// };

const CheckModuleCreateAccess = (ModuleID: number) => {
  console.log(ModuleID);
  console.log("UserID=" + sessionStorage.get("user_id"));
  console.log(Array.isArray(rbacArray.UserModules)); // Should return true
  console.log(typeof rbacArray.UserModules); // Should return 'object'

  if (ModuleID && Array.isArray(rbacArray.UserModules)) {
    const moduleAccess = rbacArray.UserModules.find(
      (p: { ModuleId: number }) => p.ModuleId === ModuleID
    );
    if (moduleAccess) {
      if (moduleAccess.Role.Permissions[0].create) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const CheckModuleEditAccess = (ModuleID: number) => {
  console.log(ModuleID);
  console.log("UserID=" + sessionStorage.get("user_id"));
  console.log(Array.isArray(rbacArray.UserModules)); // Should return true
  console.log(typeof rbacArray.UserModules); // Should return 'object'

  if (ModuleID && Array.isArray(rbacArray.UserModules)) {
    const moduleAccess = rbacArray.UserModules.find(
      (p: { ModuleId: number }) => p.ModuleId === ModuleID
    );
    if (moduleAccess) {
      if (moduleAccess.Role.Permissions[0].update) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const CheckModuleDeleteAccess = (ModuleID: number) => {
  console.log(ModuleID);
  console.log("UserID=" + sessionStorage.get("user_id"));
  console.log(Array.isArray(rbacArray.UserModules)); // Should return true
  console.log(typeof rbacArray.UserModules); // Should return 'object'

  if (ModuleID && Array.isArray(rbacArray.UserModules)) {
    const moduleAccess = rbacArray.UserModules.find(
      (p: { ModuleId: number }) => p.ModuleId === ModuleID
    );
    if (moduleAccess) {
      if (moduleAccess.Role.Permissions[0].delete) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export {
  Authorization,
  CheckUserModuleAccess,
  CheckModuleCreateAccess,
  CheckModuleEditAccess,
  CheckModuleDeleteAccess,
  CheckAdminAccess,
};
