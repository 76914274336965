import React from "react";
import axios from "axios";
import { sessionStorage } from "es-storage";
const token = sessionStorage.get("HS_token");
const current_user = sessionStorage.get("user_name");

interface tenant {
  Name: string;
  Logo: string;
  TaxID: string;
  Phone: string;
  Mobile: string;
  Address: string;
  Email: string;
  Website: string;
  POBox: string;
}

const addTenant = async (props: any) => {
  try {
    var req = await axios.post(
      `${process.env.REACT_APP_TENANT_SERVICE_API_ADD}`,
      {
        Name: props.Name,
        Mobile: props.Mobile,
        Email: props.Email,
        Address: props.Address,
        REPName: props.REPName,
        REPEmail: props.REPEmail,
        SubscriptionModules:props.subscriptionModules
      }
    );

    //sessionStorage.set("tenanTID", req.data.id);
    console.log(req);
    return req;
  } catch (err: any) {
    console.log(err);
    // if ((err.response.status = 404)) {
    //   // const navigate = useNavigate();
    //   alert(err);
    //   // navigate("/", { replace: true });
    // }
    throw err;
  }
};

const getTenant = async (id: any) => {
  try {
    var req = await axios.get(`${process.env.REACT_APP_TENANT_GET_ONE}/${id}`);

    //sessionStorage.set("tenanTID", req.data.id);
    console.log(req);
    return req;
  } catch (error: any) {
    console.log(error);
    // if ((err.response.status = 404)) {
    //   // const navigate = useNavigate();
    //   alert(err);
    //   // navigate("/", { replace: true });
    // }
    throw error;
  }
};

const updateTenantInfo = async (props: tenant) => {
  // alert(props.id)
  try {
    // alert(
    //   `ID==${props.ID} \n Name==${props.Name} \n Email==${props.Email} \n Address==${props.Address}`
    // );
    const cid = sessionStorage.get("tenant_id");
    // alert(props.Name);
    var req: any = await axios.put(
      `${process.env.REACT_APP_TENANT_Update_ONE}/${cid}`,
      {
        name: props.Name,
        logo: props.Logo,
        taxid: props.TaxID,
        phone: props.Phone,
        mobile: props.Mobile,
        address: props.Address,
        email: props.Email,
        website: props.Website,
        pobox: props.POBox,
        UpdatedBy: current_user,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    console.log(err);
    // if ((err.response.status = 404)) {
    //   // const navigate = useNavigate();
    //   alert(err);
    //   // navigate("/", { replace: true });
    // }
    throw err;
  }
};

export { addTenant, getTenant, updateTenantInfo };
