import React from "react";
import "./UsersConfiguration.css";

const UsersConfiguration = () => {
  return (
    <div className="UserConf">
      <h1>Users Configuration Section Comming Soon</h1>
    </div>
  );
};

export default UsersConfiguration;
