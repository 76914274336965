import React from "react";
import "./PDF.css";
import { useRef } from "react";
import { usePDF } from "react-to-pdf";
import logo from "../../../../assest/logo.png";
import { localStorage, sessionStorage } from "es-storage";
const PDFGenerater = (Props: any) => {
  const { toPDF, targetRef } = usePDF({ filename: "page.pdf" });
  const TenantLogo = sessionStorage.get("company_logo");

  const QuotationItemlistArray = Props.PropsObj.QuotationItemsList;
  //
  return (
    <div>
      <button onClick={() => toPDF()}>Generate PDF</button>
      <div
        className="quotationMain"
        style={{ position: "absolute", left: "-9999px" }}
        ref={targetRef}
      >
        {/* header */}
        <div className="header">
          <img
            src={TenantLogo || logo}
            style={{ width: "100px", height: "100px" }}
          ></img>
          {/* <h1>{Props.PropsObj.CompanyName}</h1>
          <p>{Props.PropsObj.CompanyAddress}</p>
          <p>{Props.PropsObj.CompanyPhone}</p>
          <p>{Props.PropsObj.CompanyEmail}</p> */}
          <h1>{sessionStorage.get("company_name")}</h1>
          <p>{sessionStorage.get("company_address")}</p>
          <p>{sessionStorage.get("company_phone")}</p>
          <p>{sessionStorage.get("company_mobile")}</p>
          <p>{sessionStorage.get("company_email")}</p>
        </div>
        {/*  */}
        <div className="quotation">
          <h2>Quotation</h2>
          <div className="quotationinfo">
            <div className="customer-info">
              <p>
                <strong>Customer:</strong> {Props.PropsObj.CustomerName}
              </p>
              <p>
                <strong>Email:</strong> {Props.PropsObj.CustomerEmail}
              </p>
              <p>
                <strong>Date:</strong> May 10, 2024
              </p>
            </div>
            <div className="customer-info">
              <p>
                <strong>Quotation ID:</strong> {Props.PropsObj.QuotationID}
              </p>
              <p>
                <strong>Quotation Date:</strong> {Props.PropsObj.QuotationCDate}
              </p>
              <p>
                <strong>Quotation Valid Until:</strong>
                {Props.PropsObj.QuotationEDate}
              </p>
              <p>
                <strong>Quotation Status:</strong>
                {Props.PropsObj.QuotationStatus}
              </p>
            </div>
          </div>
          <table className="items">
            <thead>
              <tr>
                <th>Item</th>
                <th>Description</th>
                <th>Quantity</th>
                <th>Unit Price</th>
                <th>Sub Total</th>
              </tr>
            </thead>
            <tbody>
              {QuotationItemlistArray ? (
                QuotationItemlistArray.map((item: any, index: any) => (
                  <tr key={index}>
                    <td>{item.ProductID}</td>
                    <td>{item.Description}</td>
                    <td>{item.Quantity}</td>
                    <td>{item.UnitPrice}</td>
                    <td>{item.Subtotal}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={5}>No items found</td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="priceDiv">
            <div className="priceRow">
              <p>
                <strong>Untaxed Amount:</strong>
              </p>
              <p>{Props.PropsObj.QuotationUntaxedAmount} Birr</p>
            </div>
            <div className="priceRow">
              <p>
                <strong>Taxed Amount:</strong>
              </p>
              <p>{Props.PropsObj.QuotationTaxedAmount} Birr</p>
            </div>
            <div className="priceRow total">
              <p>
                <strong>Total:</strong>
              </p>
              <p>{Props.PropsObj.QuotationTotalPrice} Birr</p>
            </div>
          </div>
        </div>
        {/* footer */}
        <div className="footer">
          <p>
            Thank you for choosing {sessionStorage.get("company_name")} Company
            for your business needs.
          </p>
          <p>{sessionStorage.get("company_website")}</p>
        </div>
        {/*  */}
      </div>
    </div>
  );
};

// const GeneratePDF = toPDF()

export default PDFGenerater;
