import * as React from "react";
import "./productTable.css";
import { useLocation } from "react-router-dom";
////Access Controle Middle Wares
import {
  CheckModuleCreateAccess,
  CheckModuleEditAccess,
  CheckModuleDeleteAccess,
} from "../../../middleware/authorizationMiddleware";
//
import { useEffect, useState } from "react";
//ant design
import { Spin } from "antd";
import type { RadioChangeEvent } from "antd";
import { Flex, Radio } from "antd";
import { Tooltip, Popover } from "antd";
//ant design icons
import { ProductOutlined } from "@ant-design/icons";
//
import { Base64 } from "js-base64";
//
import { useNavigate } from "react-router-dom";
//
import DocViewer from "../MyDocViewer/MyDocViewer";
//
import { localStorage, sessionStorage } from "es-storage";
//notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//mui
import { DataGrid, GridColDef } from "@mui/x-data-grid";
//Api
import {
  addProduct,
  getAllProduct,
  updateSingeleProduct,
  deleteSingeleProduct,
} from "../../../API/productAPI";
import { getAllProductCategories } from "../../../API/productcategoriesAPI";
import { getAllProductSubCategories } from "../../../API/productsubcategoriesAPI ";
import { getAllSupplier } from "../../../API/supplierAPI";
import { getAllWarehouse } from "../../../API/warehouseAPI";
//midewares
import { Authorization } from "../../../middleware/authorizationMiddleware";
//material ui badge
import Badge from "@mui/material/Badge";
import FolderIcon from "@mui/icons-material/Folder";
import InventoryIcon from "@mui/icons-material/Inventory";
import SellIcon from "@mui/icons-material/Sell";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
//
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
//
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridSlots,
} from "@mui/x-data-grid";
import { Category, Description } from "@mui/icons-material";
import { relative } from "path";
//

// Define interface for product object
interface Product {
  id: number;
  Name: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  updatedBy: string;
}

interface ProductCat {
  id: number;
  name: string;
  uuid: string;
}

interface TypeProductSubCategory {
  id: number;
  name: string;
  uuid: string | number;
  ProductCategorieId: number;
}

//material ui modal
const productEditModalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1200,
  // height: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const styleNewProductModel = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  // height: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
//

export default function ProductTable() {
  //
  //
  const location = useLocation();
  const moduleid = location.state?.moduleId;
  console.log(moduleid);
  //spin state
  const [loading, setLoading] = React.useState<boolean>(false);
  //
  const navigate = useNavigate();
  //ant design state
  const [RadioValue, setRadioValue] = useState(1);
  //
  const userRole = sessionStorage.get("user_role");
  const [isAdmin, setIsAdmin] = useState(false);
  useEffect(() => {
    if (userRole == "Admin") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, []);
  //
  //
  const [productWarehouseData, setproductWarehouseData] = useState<any[]>([]);
  const [productSubCategorieData, setProductSubCategorieData] = useState([]);
  const [filterdSubCategorieData, setfilterdSubCategorieData] = useState<any[]>(
    []
  );
  const [productData, setproductData] = useState<Product[]>([]);
  const [selectedRows, setSelectedRows] = useState<Product[]>([]);
  //Edit variabls
  const EditSubCategoryArray: any = [];
  const [ProductID, setProductID] = useState(0);
  const [ProductName, setProductName] = useState("");
  const [EditProductInternalRef, setEditProductInternalRef] = useState("");
  const [EditProductCategorieID, setEditProductCategorieID] = useState("");
  const [EditProductSubCategorieID, setEditProductSubCategorieID] =
    useState("");
  const [EditProductSubCategorieUUID, setEditProductSubCategorieUUID] =
    useState("");
  const [EditProductWarehouseID, setEditProductWarehouseID] = useState("");
  const [EditProductModel, setEditProductModel] = useState("");
  const [EditProductBrand, setEditProductBrand] = useState("");
  const [EditProductCategoryUUID, setEditProductCategoryUUID] = useState("");
  const [EditedProductDoc, setEditedProductDoc] = useState("");
  const [EditedProductPurchasedMethod, setEditedProductPurchasedMethod] =
    useState("");
  const [EditedProductDescription, setEditedProductDescription] = useState("");
  const [EditProductQuantity, setEditProductQuantity] = useState("");
  const [EditProductCostPrice, setEditProductCostPrice] = useState("");
  const [EditProductSalePrice, setEditProductSalePrice] = useState("");
  const [EditProductSupplierID, setEditProductSupplierID] = useState("");
  const [EditProductImage, setEditProductImage] = useState("");
  const [ProductImage, setProductImage] = useState("");
  const [ProductCreatedBy, setProductCreatedBy] = useState("");
  const [ProductCreatedAt, setProductCreatedAt] = useState("");
  const [ProductUpdatedBy, setProductUpdatedBy] = useState("");
  const [ProductUpdatedAt, setProductUpdatedAt] = useState("");

  //New Product Variables
  const [NewProductName, setNewProductName] = useState("");
  const [NewProductImage, setNewProductImage] = useState("");
  const [NewProductDoc, setNewProductDoc] = useState("");
  const [NewProductDescription, setNewProductDescription] = useState("");
  const [NewProductQuantity, setNewProductQuantity] = useState("");
  const [NewProductPrice, setNewProductPrice] = useState("");
  const [NewProductCostPrice, setNewProductCostPrice] = useState("");
  const [NewProductPurchasedMethod, setNewProductPurchasedMethod] =
    useState("");
  const [NewProductModel, setNewProductModel] = useState("");
  const [NewProductBrand, setNewProductBrand] = useState("");
  const [NewProductCategoryUUID, setNewProductCategoryUUID] = useState("");
  const [NewProductSubCategoryUUID, setNewProductSubCategoryUUID] =
    useState("");
  const [NewProductInternalRef, setNewProductInternalRef] = useState("");
  const [NewProductDocument, setNewProductDocument] = useState("");

  //
  const [NewProductWarehouseID, setNewProductWarehouseID] = useState("");
  const [ProductWarehouseA, setProductWarehouseA] = useState([]);
  const ProductWarehouse: any = [];
  //
  //
  const [NewProductCategorieID, setNewProductCategorieID] = useState("");
  const [NewProductSubCategorieID, setNewProductSubCategorieID] = useState("");
  const [ProductCategorieA, setProductCategorieA] = useState([]);
  const ProductCategorie: any = [];
  //
  const [NewProductCategory, setNewProductCategory] = useState("");
  const [NewProductSupplierID, setNewProductSupplierID] = useState("");
  const [ProductSupplierA, setProductSupplierA] = useState([]);
  const ProductSupplier: any = [];
  //Product Badges Status
  const [ProductDocBadge, setProductDocBadge] = useState(0);
  const [ProductInventoryBadge, setProductInventoryBadge] = useState(0);
  const [ProductSoldBadge, setProductSoldBadge] = useState(0);
  const [ProductPurchasedBadge, setProductPurchasedBadge] = useState(0);
  //

  //for modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  //
  //mui add model
  const [openAddModel, setOpenAddModel] = React.useState(false);
  const handleOpenAddModel = () => setOpenAddModel(true);
  const handleCloseAddModel = () => setOpenAddModel(false);
  //
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );
  // Notifications
  const notifyErr = (msg: any) => toast.error(msg || "Error!");
  const notifyDone = () => toast("Sucess");

  const notifyError = (message: any) =>
    toast.error(`error! \n ${message}`, {
      position: "top-center",
      theme: "dark",
    });
  const notify_success = (message: any) =>
    toast.success(`Message: \n ${message}`, {
      position: "top-center",
      theme: "colored",
    });
  //

  //ant desion methods
  const onRadioChange = (e: RadioChangeEvent) => {
    console.log("radio checked", e.target.value);
    setRadioValue(e.target.value);
  };
  //

  //Custom functions
  const handleAddProduct = async (event: any) => {
    event.preventDefault();

    try {
      //
      setLoading(true);
      //
      //alert(NewProductWarehouseID);
      //set suppliers
      //
      var req = await addProduct({
        Name: NewProductName,
        InternalRef: NewProductInternalRef,
        Model: NewProductModel,
        Brand_Name: NewProductBrand,
        Purchase_Method: NewProductPurchasedMethod,
        Document: NewProductDocument,
        Description: NewProductDescription,
        Image: NewProductImage,
        Quantity: NewProductQuantity,
        Cost_Price: NewProductCostPrice,
        Sales_Price: NewProductPrice,
        Supplier_ID: NewProductSupplierID,
        Warehouse_ID: NewProductWarehouseID,
        ProductCategorie_ID: NewProductCategorieID,
        ProductSubCategorie_ID: NewProductSubCategorieID,
      });
      console.log(req);
      GetProducts();
      notify_success(req.data);
    } catch (err: any) {
      console.log(err);
      if (err.response.data.message) {
        notifyError(err.response.data.message);
      }

      if (err.response.statusText) {
        notifyError(err.response.statusText);
      }
    } finally {
      //
      setLoading(false);
      //
    }
  };

  const HandleGetSuppliers = async (e: any) => {
    e.preventDefault();
    try {
      const call = await getAllSupplier();
      //console.log(call.data[0].id);
      const data: any[] = call.data;
      console.log(data.length);
      for (let i = 0; i < data.length; i++) {
        ProductSupplier.push({
          id: data[i].id,
          name: data[i].name,
        });
      }
      console.log(ProductSupplier);
      setProductSupplierA(ProductSupplier);
    } catch (err) {
      console.log(err);
    }
  };

  const HandleGetProductCategories = async (e: any) => {
    e.preventDefault();
    try {
      const call = await getAllProductCategories();
      //console.log(call.data[0].id);
      const data: any[] = call.data;
      //console.log(data.length);
      for (let i = 0; i < data.length; i++) {
        ProductCategorie.push({
          id: data[i].id,
          name: data[i].name,
          uuid: data[i].uuid,
        });
      }
      console.log(ProductCategorie);
      setProductCategorieA(ProductCategorie);
    } catch (err) {
      console.log(err);
    }
  };

  const HandleGetProductSubCategories = async (e: any) => {
    e.preventDefault();
    try {
      const call: any = await getAllProductSubCategories();
      const data: any[] = call.data;
      for (let i = 0; i < data.length; i++) {
        EditSubCategoryArray.push({
          id: data[i].id,
          name: data[i].name,
          uuid: data[i].uuid,
        });
      }
      //console.log(EditSubCategoryArray);
      setProductSubCategorieData(call.data);
    } catch (err) {
      console.log(err);
    }
  };

  const HandleGetWarehouses = async (e: any) => {
    e.preventDefault();
    try {
      const call = await getAllWarehouse();
      //console.log(call.data[0].id);
      const data: any[] = call.data;
      console.log(data.length);
      for (let i = 0; i < data.length; i++) {
        ProductWarehouse.push({
          id: data[i].id,
          name: data[i].name,
        });
      }
      console.log(ProductWarehouse);
      setProductWarehouseA(ProductWarehouse);
    } catch (err) {
      console.log(err);
    }
  };

  const HandleEdit = async (event: any) => {
    event.preventDefault();
    try {
      //
      setLoading(true);
      //
      //
      // alert(
      //   `ID==${supplierID} \n Name==${supplierName} \n Email==${supplierEmail} \n Address==${supplierAddress}`
      // );
      //
      const call = await updateSingeleProduct({
        ID: ProductID,
        InternalRef: EditProductInternalRef,
        Name: ProductName,
        Model: EditProductModel,
        Brand_Name: EditProductBrand,
        Document: NewProductDoc,
        Description: EditedProductDescription,
        Image: EditProductImage,
        Quantity: EditProductQuantity,
        Cost_Price: EditProductCostPrice,
        Sales_Price: EditProductSalePrice,
        ProductCategorie_ID: EditProductCategorieID,
        ProductSubCategorie_ID: EditProductSubCategorieID,
        Supplier_ID: EditProductSupplierID,
        Purchase_Method: EditedProductPurchasedMethod,
        Warehouse_ID: EditProductWarehouseID,
      });
      console.log(call);
      GetProducts();
      notifyDone();
    } catch (err: any) {
      console.log(err);
      notifyErr(err);
    } finally {
      //
      setLoading(false);
      //
    }
  };

  const handleDocumentChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Set the base64 string as the source of the image
        //setNewProductDoc(Base64.encode(reader.result as string));
        //setProductImage(reader.result as string);
      };
      // Read the file as a data URL
      reader.readAsDataURL(file);
    }
    //converted the file into base64 format
    //setNewProductDoc(Base64.encodeURI(file));
  };

  const handleEditDocumentChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Set the base64 string as the source of the image
        //setNewProductDoc(Base64.encode(reader.result as string));
        setEditedProductDoc(reader.result as string);
      };
      // Read the file as a data URL
      reader.readAsDataURL(file);
    }
    //converted the file into base64 format
    //setNewProductDoc(Base64.encodeURI(file));
  };

  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Set the base64 string as the source of the image
        setNewProductImage(reader.result as string);
      };
      // Read the file as a data URL
      reader.readAsDataURL(file);
    }
  };

  const handleUpdateImageChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Set the base64 string as the source of the image
        setEditProductImage(reader.result as string);
      };
      // Read the file as a data URL
      reader.readAsDataURL(file);
    }
  };

  const handleProductDocChange = (e: any) => {
    // Check if file size exceeds 10 MB
    const file = e.target.files[0];
    if (file) {
      if (file.size > 10485760) {
        console.log(file.size);
        alert("doc file size exced 10MB");
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        // Set the base64 string as the source of the image
        setNewProductDocument(reader.result as string);
      };
      // Read the file as a data URL
      reader.readAsDataURL(file);
    }
  };
  //filter subcategory based on parrent
  // const fillterSubCategory = async () => {
  //   if (ProductCategorieA && ProductCategorieA.length > 0) {
  //     const filteredArray = productSubCategorieData.filter(
  //       (category: any) => category.ProductCategorieId === NewProductCategorieID
  //     );
  //     setProductSubCategorieData(filteredArray);
  //   }
  // };
  // useEffect(() => {
  //   if (NewProductCategorieID) {
  //     fillterSubCategory();
  //   }
  // }, [NewProductCategorieID]);
  //

  // Function to update internal reference based on Model and Brand Name
  const updateInternalReference = () => {
    const internalRef = `${NewProductCategoryUUID}_${NewProductSubCategoryUUID}_${NewProductBrand}_${NewProductModel}`;
    setNewProductInternalRef(internalRef);
  };

  const EditInternalReference = () => {
    const internalRef = `${EditProductCategoryUUID}_${EditProductSubCategorieUUID}_${EditProductBrand}_${EditProductModel}`;
    setEditProductInternalRef(internalRef);
  };

  const handleEditCategoryChange = (event: any) => {
    const selectedOption = event.target.value;
    const [selectedCategoryId, selectedCategoryUuid] =
      selectedOption.split(",");

    setEditProductCategorieID(selectedCategoryId);
    setEditProductCategoryUUID(selectedCategoryUuid);
  };

  // Update internal reference whenever Model or Brand Name changes
  useEffect(() => {
    updateInternalReference();
  }, [
    NewProductModel,
    NewProductBrand,
    NewProductCategorieID,
    NewProductCategoryUUID,
    NewProductSubCategoryUUID,
  ]);

  useEffect(() => {
    EditInternalReference();
  }, [
    productData,
    EditProductCategoryUUID,
    EditProductSubCategorieUUID,
    EditProductBrand,
    EditProductModel,
  ]);

  //
  const handleFilterdSubCategoryChange = async (
    parentCategoryID: number | String
  ) => {
    console.log(parentCategoryID);
    console.log(productSubCategorieData);
    //
    let fillterdSubCategory = productSubCategorieData.filter(
      (value: any) => value.ProductCategorieId == parentCategoryID
    );
    console.log(fillterdSubCategory);
    // let array = [];
    // array.push(fillterdSubCategory);
    setfilterdSubCategorieData(fillterdSubCategory);
  };
  //
  const handleEditSubCategoryChange = (event: any) => {
    const selectedOption = event.target.value;
    const [selectedCategoryId, selectedCategoryUuid] =
      selectedOption.split(",");

    setEditProductSubCategorieID(selectedCategoryId);
    setEditProductSubCategorieUUID(selectedCategoryUuid);
  };

  //
  const handleEditClick = (id: GridRowId) => async (event: any) => {
    //setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    //setEditID(id.toString);
    //event.preventDefault();
    //const event = new Event;
    try {
      await HandleGetSuppliers(event);
      await HandleGetWarehouses(event);
      await HandleGetProductCategories(event);
      await HandleGetProductSubCategories(event);
    } catch (err) {
      console.log("err");
    }

    const editedRow: any = productData.find((row) => row.id === id);
    const editedProductCategorie: any = ProductCategorie.find(
      (row: any) => row.id === editedRow.ProductCategorieId
    );
    const editedProductSubCategorie: any = EditSubCategoryArray.find(
      (row: any) => row.id === editedRow.ProductSubCategorieId
    );
    console.log("Row=" + editedRow.SupplierId);
    handleOpen();

    try {
      setProductID(editedRow.id);
      setProductName(editedRow.name);
      setEditProductInternalRef(editedRow.internalref);
      setEditProductModel(editedRow.model);
      setEditProductBrand(editedRow.brand_name);
      setEditProductQuantity(editedRow.quantity);
      setEditedProductPurchasedMethod(editedRow.purchase_method);
      setEditProductCostPrice(editedRow.cost_price);
      setEditProductSalePrice(editedRow.sales_price);
      // setProductImage(editedRow.image);
      setEditProductImage(editedRow.image);
      setEditedProductDoc(editedRow.document);
      setEditProductCategorieID(editedRow.ProductCategorieId);
      setEditProductSubCategorieID(editedRow.ProductSubCategorieId);
      setEditProductCategoryUUID(editedProductCategorie.uuid);
      setEditProductSubCategorieUUID(editedProductSubCategorie.uuid);
      setEditProductSupplierID(editedRow.SupplierId);
      setEditProductWarehouseID(editedRow.WarehouseId);
      setproductWarehouseData(editedRow.Product_Warehouses);
      //setProductSupplierA(editedRow.SupplierId);
      setEditedProductDescription(editedRow.description);
      //auther
      setProductCreatedBy(editedRow.createdBy);
      setProductCreatedAt(editedRow.createdAt);
      setProductUpdatedBy(editedRow.updatedBy);
      setProductUpdatedAt(editedRow.updatedAt);
      //
      if (editedRow.document) {
        setProductDocBadge(1);
      } else {
        setProductDocBadge(0);
      }
      if (editedRow.quantity) {
        setProductInventoryBadge(editedRow.quantity);
      } else {
        setProductInventoryBadge(0);
      }
      //
      console.log("ProductWarehouse:" + editedRow.Product_Warehouses);
      handleOpen();
      //
    } catch (err: any) {
      console.log(err);
    }
    // alert(`CID=${EditProductCategorieID} \n CUUID=${EditProductCategoryUUID}`);
  };

  const handleSaveClick = (id: GridRowId) => () => {
    //setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => async () => {
    //setRows(rows.filter((row) => row.id !== id));
    //alert("delete");
    try {
      //
      setLoading(true);
      //
      const call = await deleteSingeleProduct({ id: id });
      console.log(call);
      GetProducts();
      notifyDone();
    } catch (err: any) {
      console.log(err);
      notifyErr(err.response.data);
    } finally {
      //
      setLoading(false);
      //
    }
  };

  const handleCancelClick = (id: GridRowId) => () => {
    // setRowModesModel({
    //   ...rowModesModel,
    //   [id]: { mode: GridRowModes.View, ignoreModifications: true },
    // });
    // const editedRow = rows.find((row) => row.id === id);
    // if (editedRow!.isNew) {
    //   setRows(rows.filter((row) => row.id !== id));
    // }
  };
  //

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "internalref", headerName: "Internal Ref", width: 100 },
    { field: "name", headerName: "Product Name", width: 130 },
    { field: "model", headerName: "Model", width: 130 },
    { field: "brand_name", headerName: "Brand Name", width: 130 },
    { field: "document", headerName: "Product Doc", width: 130 },
    { field: "description", headerName: "Product Description", width: 130 },
    { field: "image", headerName: "Product Image", width: 130 },
    {
      field: "quantity",
      valueGetter: (value, row) => {
        function sumQuantity() {
          let sum = 0;
          if (row.Product_Warehouses) {
            for (let i = 0; i < row.Product_Warehouses.length; i++) {
              sum += row.Product_Warehouses[i].quantity;
            }
          }
          return sum;
        }
        return `${sumQuantity()}`;
      },
      headerName: "On Hand",
      width: 80,
    },
    { field: "cost_price", headerName: "Cost Price", width: 100 },
    { field: "sales_price", headerName: "Sale Price", width: 100 },
    { field: "purchase_method", headerName: "Purchase Method", width: 100 },
    {
      field: "ProductCategorieId",
      headerName: "Product Categorie Id",
      width: 70,
    },
    {
      field: "ProductCategorieUUID",
      type: "string",
      valueGetter: (value, row) => {
        return `${row.ProductCategorie ? row.ProductCategorie.uuid : ""}`;
      },
      headerName: "PC UUID",
      width: 100,
    },
    {
      field: "ProductSubCategorieId",
      headerName: "Product Categorie Id",
      width: 70,
    },
    {
      field: "ProductSubCategorieUUID",
      valueGetter: (value, row) => {
        return `${row.ProductSubCategorie ? row.ProductSubCategorie.uuid : ""}`;
      },
      headerName: "ProductSubCategorieUUID",
      width: 100,
    },
    { field: "SupplierId", headerName: "Supplier ID", width: 70 },
    { field: "WarehouseId", headerName: "Warehouse ID", width: 70 },
    {
      field: "ProductCategorie",
      valueGetter: (value, row) => {
        return `${row.ProductCategorie ? row.ProductCategorie.name : ""}`;
      },
      headerName: "Categorie",
      width: 100,
    },
    {
      field: "ProductSubCategorie",
      valueGetter: (value, row) => {
        return `${row.ProductSubCategorie ? row.ProductSubCategorie.name : ""}`;
      },
      headerName: "SubCategorie",
      width: 100,
    },

    {
      field: "Supplier",
      valueGetter: (value, row) => {
        return `${row.Supplier ? row.Supplier.name : ""}`;
      },
      headerName: "Supplier",
      width: 100,
    },
    {
      field: "Warehouse",
      valueGetter: (value, row) => {
        return `${row.Warehouse ? row.Warehouse.name : ""}`;
      },
      headerName: "Warehouse",
      width: 100,
    },
    {
      field: "Product_Warehouses",
      valueGetter: (value, row) => {
        return `${row.Product_Warehouses ? row.Product_Warehouses : ""}`;
      },
      headerName: "Product Warehouse",
      width: 100,
    },
    { field: "createdBy", headerName: "Created By", width: 130 },
    { field: "createdAt", headerName: "Created At", width: 130 },
    { field: "updatedBy", headerName: "Last Updated By", width: 130 },
    { field: "updatedAt", headerName: "Updated At", width: 130 },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          CheckModuleEditAccess(moduleid) ? (
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />
          ) : (
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              // onClick={handleEditClick(id)}
              sx={{ color: "grey.500", opacity: 0.5 }} // Color for disabled Delete button
            />
          ),

          CheckModuleDeleteAccess(moduleid) ? (
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
            />
          ) : (
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              // onClick={handleDeleteClick(id)}
              sx={{ color: "grey.500", opacity: 0.5 }} // Color for disabled Delete button
            />
          ),
        ];
      },
    },
    // {
    //   field: "age",
    //   headerName: "Age",
    //   type: "number",
    //   width: 90,
    // },
    // {
    //   field: "fullName",
    //   headerName: "Full name",
    //   description: "This column has a value getter and is not sortable.",
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (value, row) => `${row.firstName || ""} ${row.lastName || ""}`,
    // },
  ];
  //

  const GetProducts = async () => {
    try {
      var req: any = await getAllProduct();
      setproductData(req.data);
      console.log(req.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    GetProducts();
  }, []);

  return (
    <div style={{ height: "97%", width: "100%" }}>
      <ToastContainer />
      {/* for modal */}
      <div>
        {CheckModuleCreateAccess(moduleid) ? (
          <Button
            onClick={(event) => {
              handleOpenAddModel();
              HandleGetSuppliers(event);
              HandleGetWarehouses(event);
              HandleGetProductCategories(event);
              HandleGetProductSubCategories(event);
            }}
          >
            New Product
          </Button>
        ) : (
          // <div className="AppCard">Request The Admin for Log Modul</div>
          "Access denied"
        )}
      </div>

      {/* modals */}

      {/* Add Model */}
      <Modal
        open={openAddModel}
        onClose={handleCloseAddModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleNewProductModel}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <div className="ProductEditModalHeaderDiv">
              <h3>
                <ProductOutlined />
                New Product for Sale
              </h3>
            </div>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Spin spinning={loading}>
              <form className="NewProductForm" onSubmit={handleAddProduct}>
                <div className="NewProductModelDiv">
                  <div>
                    <div>
                      <label className="productlabels">
                        Product Name:
                        <input
                          type="text"
                          placeholder="e.g Pump"
                          onChange={(event) =>
                            setNewProductName(event.target.value)
                          }
                          minLength={2}
                          maxLength={100}
                          required
                        ></input>
                      </label>

                      <label className="productlabels">
                        Internal Reference :
                        <input
                          type="text"
                          placeholder="01_01_Ustunel_S4CR8D03/15"
                          value={NewProductInternalRef}
                          // onChange={(event) =>
                          //   setNewProductInternalRef(event.target.value)
                          // }
                          minLength={2}
                          maxLength={100}
                          required
                          readOnly
                        ></input>
                      </label>

                      {/* <div className="productPropertyCheckDiv">
                      <Radio.Group onChange={onRadioChange} value={RadioValue}>
                        <Radio value={1}>Fixed Asset</Radio>
                        <Radio value={2}>Non Fixed Asset</Radio>
                      </Radio.Group>
                    </div> */}
                    </div>

                    <label className="productlabels">
                      Product Quantity:
                      <input
                        type="number"
                        placeholder=""
                        onChange={(event) =>
                          setNewProductQuantity(event.target.value)
                        }
                        min={1}
                        required
                      ></input>
                    </label>

                    <label className="productlabels">
                      Cost Price:
                      <input
                        type="number"
                        placeholder="100.00"
                        onChange={(event) =>
                          setNewProductCostPrice(event.target.value)
                        }
                        min={1}
                        required
                      ></input>
                    </label>

                    <label className="productlabels">
                      Sales Price:
                      <input
                        type="number"
                        placeholder="100.00"
                        onChange={(event) =>
                          setNewProductPrice(event.target.value)
                        }
                        min={1}
                        required
                      ></input>
                    </label>

                    {/* <label className="productlabels">
                      Product Category:
                      <select
                        multiple={false}
                        onChange={(event) =>
                          setNewProductCategory(event.target.value)
                        }
                      >
                        <option value="">Select a Category</option>
                        <optgroup label="Pump">
                          <option value="pump/Submersible">Submersible</option>
                          <option value="pump/surface">Surface</option>
                          <option value="pump/hotwater">Hot Water</option>
                        </optgroup>
                        <option value="medical_gas_system">
                          Medical Gas Sysytem
                        </option>
                        <option value="coffee_processing_machinary">
                          Coffe Processing Machinery
                        </option>
                        <optgroup label="Solar System">
                          <option value="solar/mono">Mono</option>
                          <option value="solar/poly">Poly</option>
                        </optgroup>
                      </select>
                    </label> */}

                    <label className="productlabels">
                      Product Category:
                      <select
                        multiple={false}
                        onChange={async (event) => {
                          const selectedOption = event.target.value;
                          const selectedCategoryId =
                            selectedOption.split(",")[0];
                          const selectedCategoryUuid =
                            selectedOption.split(",")[1];

                          setNewProductCategorieID(selectedCategoryId);
                          setNewProductCategoryUUID(selectedCategoryUuid);
                          handleFilterdSubCategoryChange(selectedCategoryId);
                          // setNewProductCategoryUUID(event.target.value[1]);
                          // setNewProductCategorieID(event.target.value[0]);
                          // alert(
                          //   `ID=${event.target.value[0]}\n UUID=${event.target.value[1]}`
                          // );
                        }}
                        required
                      >
                        <option value="">Select a Product Categorie</option>
                        {ProductCategorieA.map((categorie: any) => (
                          <option
                            key={categorie.id}
                            value={`${categorie.id},${categorie.uuid}`}
                          >
                            {categorie.name}
                          </option>
                        ))}
                      </select>
                    </label>

                    <label className="productlabels">
                      Sub Category:
                      <select
                        multiple={false}
                        onChange={(event) => {
                          const selectedOption = event.target.value;
                          const selectedCategoryId =
                            selectedOption.split(",")[0];
                          const selectedCategoryUuid =
                            selectedOption.split(",")[1];

                          setNewProductSubCategorieID(selectedCategoryId);
                          setNewProductSubCategoryUUID(selectedCategoryUuid);
                        }}
                        required
                      >
                        <option value="">Select Sub Categorie</option>
                        {filterdSubCategorieData.map((categorie: any) => (
                          <option
                            key={categorie.id}
                            value={`${categorie.id},${categorie.uuid}`}
                          >
                            {categorie.name}
                          </option>
                        ))}
                      </select>
                    </label>

                    <label className="productlabels">
                      Model:
                      <input
                        type="text"
                        placeholder="S4CR8D03/15"
                        onChange={(event) =>
                          setNewProductModel(event.target.value)
                        }
                        minLength={2}
                        maxLength={100}
                        required
                      ></input>
                    </label>

                    <label className="productlabels">
                      Brand Name:
                      <input
                        type="text"
                        placeholder="e.g Caterpillar, Ustunel"
                        onChange={(event) =>
                          setNewProductBrand(event.target.value)
                        }
                        minLength={2}
                        maxLength={100}
                        required
                      ></input>
                    </label>
                  </div>

                  {/* <label className="productlabels">
                      Product Documentation:
                      <div>
                        <input
                          type="file"
                          accept=".pdf,.txt,.DOC,.DOCX,.CSV,.PPT,.PPTX,XLS,.XLSX"
                          onChange={handleDocumentChange}
                        />
                      </div>
                    </label> */}
                  <div className="NewProductImgContainer">
                    <label className="productlabels">
                      Product Supplier:
                      <select
                        multiple={false}
                        onChange={(event) =>
                          setNewProductSupplierID(event.target.value)
                        }
                        required
                      >
                        <option value="">Select a supplier</option>
                        {ProductSupplierA.map((supplier: any) => (
                          <option key={supplier.id} value={supplier.id}>
                            {supplier.name}
                          </option>
                        ))}
                      </select>
                    </label>

                    <label className="productlabels">
                      Warehouse:
                      <select
                        multiple={false}
                        onChange={(event) =>
                          setNewProductWarehouseID(event.target.value)
                        }
                        required
                      >
                        <option value="">Select a Warehouse</option>
                        {ProductWarehouseA.map((Warehouse: any) => (
                          <option key={Warehouse.id} value={Warehouse.id}>
                            {Warehouse.name}
                          </option>
                        ))}
                      </select>
                    </label>

                    <label className="productlabels">
                      Method of Purchase:
                      <select
                        multiple={false}
                        onChange={(event) =>
                          setNewProductPurchasedMethod(event.target.value)
                        }
                        required
                      >
                        <option value="">Select a Method of Purchase</option>
                        <option value="direct purchase">Direct Purchase</option>
                        <option value="import">Import</option>
                      </select>
                    </label>
                    <label className="productlabels">
                      Product Image:
                      {/* <input
                            type="file"
                            placeholder=""
                            onChange={(event) =>
                              setProductImage(event.target.value)
                            }
                            accept=".jpeg, .png, .jpg"
                          ></input> */}
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleImageChange}
                          multiple={false}
                          name="Add Image"
                        />
                        {NewProductImage && (
                          <img
                            src={NewProductImage}
                            alt="Uploaded"
                            className="ProductPreviewImg"
                            style={{
                              maxWidth: "200px",
                              maxHeight: "200px",
                            }}
                          />
                        )}
                      </div>
                    </label>

                    {/* <label className="productlabels">
                      Product Document:
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <input
                          type="file"
                          accept=".doc,.docx,.pdf,.txt,"
                          onChange={handleEditDocumentChange}
                          multiple={false}
                        />
                      </div>
                    </label> */}

                    <label className="productlabels">
                      Product Description:
                      <textarea
                        placeholder="Detail Product Description 
                      Ustunel 4” Submersible Pump"
                        onChange={(event) =>
                          setNewProductDescription(event.target.value)
                        }
                      ></textarea>
                    </label>
                  </div>
                </div>
                <button className="addbtn" type="submit">
                  Add Product
                </button>
              </form>
            </Spin>
          </Typography>
        </Box>
      </Modal>

      {/* Edit modal */}
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={productEditModalStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <div className="BadgeDiv">
                {/* <label
                  onClick={() => {
                    navigate("/docviewer", {
                      state: {
                        // Your parameters here
                        param1: EditedProductDoc,
                      },
                      replace: true,
                    });
                  }}
                >
                  Debug
                </label> */}

                {/* <Badge
                  badgeContent={ProductDocBadge}
                  color="primary"
                  aria-label="Document"
                >
                  <FolderIcon color="action" />
                </Badge>
                <Badge badgeContent={ProductInventoryBadge} color="primary">
                  <InventoryIcon color="action" />
                </Badge>

                <Badge badgeContent={ProductSoldBadge} color="primary">
                  <SellIcon color="action" />
                </Badge>

                <Badge badgeContent={ProductPurchasedBadge} color="primary">
                  <ShoppingCartIcon color="action" />
                </Badge> */}
              </div>
              <div className="ProductEditModalHeaderDiv">
                <ProductOutlined />
                Product Edit Form
              </div>
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <Spin spinning={loading}>
                <form className="editproductform" onSubmit={HandleEdit}>
                  <div className="EditProductSaleModelDiv">
                    <label className="productInternalReflabel">
                      Internal Reference:
                      <input
                        type="text"
                        placeholder=""
                        value={EditProductInternalRef}
                        minLength={2}
                        maxLength={100}
                        required
                        readOnly
                      ></input>
                    </label>
                    <div className="EditProductSaleModelContainer">
                      <div
                        style={{
                          // width: "50%",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <label className="productlabels">
                          Product ID :
                          <input
                            type="text"
                            placeholder=""
                            value={ProductID}
                            required
                            readOnly
                          ></input>
                        </label>

                        <label className="productlabels">
                          Product Name :
                          <input
                            type="text"
                            placeholder=""
                            value={ProductName}
                            onChange={(event) =>
                              setProductName(event.target.value)
                            }
                            minLength={2}
                            maxLength={100}
                            required
                          ></input>
                        </label>

                        <label className="productlabels">
                          Brand Name:
                          <input
                            type="text"
                            value={EditProductBrand}
                            placeholder="e.g Caterpillar, Ustunel"
                            onChange={(event) =>
                              setEditProductBrand(event.target.value)
                            }
                            minLength={2}
                            maxLength={100}
                            required
                          ></input>
                        </label>

                        <label className="productlabels">
                          Internal Reference:
                          <input
                            type="text"
                            placeholder=""
                            value={EditProductInternalRef}
                            // onChange={(event) =>
                            //   setEditProductInternalRef(event.target.value)
                            // }
                            minLength={2}
                            maxLength={100}
                            required
                            readOnly
                          ></input>
                        </label>

                        {/* <label className="productlabels">
                          Quantity :
                          <input
                            type="text"
                            placeholder=""
                            value={EditProductQuantity}
                            onChange={(event) =>
                              setEditProductQuantity(event.target.value)
                            }
                            min={1}
                            required
                          ></input>
                        </label> */}

                        <label className="productlabels">
                          Cost Price :
                          <input
                            type="text"
                            placeholder=""
                            value={EditProductCostPrice}
                            onChange={(event) =>
                              setEditProductCostPrice(event.target.value)
                            }
                            min={1}
                            required
                          ></input>
                        </label>

                        <label className="productlabels">
                          Sale Price :
                          <input
                            type="text"
                            placeholder=""
                            value={EditProductSalePrice}
                            onChange={(event) =>
                              setEditProductSalePrice(event.target.value)
                            }
                            min={1}
                            required
                          ></input>
                        </label>

                        {/* <label className="productlabels">
                        Product Category:
                        <select
                          multiple={false}
                          value={EditProductCategorie}
                          onChange={(event) =>
                            setEditProductCategorie(event.target.value)
                          }
                          required
                        >
                          <option value="">Select a Product Categorie</option>
                          {ProductCategorieA.map((categorie: any) => (
                            <option key={categorie.id} value={categorie.id}>
                              {categorie.name}
                            </option>
                          ))}
                        </select>
                      </label> */}
                      </div>
                      <div className="ProductAuthDiv">
                        <label className="productlabels">
                          Product Category:
                          <select
                            multiple={false}
                            value={`${EditProductCategorieID},${EditProductCategoryUUID}`}
                            onChange={(e) => handleEditCategoryChange(e)}
                            required
                          >
                            <option value="1">Select a Product Category</option>
                            {ProductCategorieA.map((categorie: any) => (
                              <option
                                key={categorie.id}
                                value={`${categorie.id},${categorie.uuid}`}
                              >
                                {categorie.name}
                              </option>
                            ))}
                          </select>
                        </label>
                        <label className="productlabels">
                          Sub Category:
                          <select
                            multiple={false}
                            value={`${EditProductSubCategorieID},${EditProductSubCategorieUUID}`}
                            onChange={(e) => handleEditSubCategoryChange(e)}
                            required
                          >
                            <option value="1">Select Sub Category</option>
                            {productSubCategorieData.map((categorie: any) => (
                              <option
                                key={categorie.id}
                                value={`${categorie.id},${categorie.uuid}`}
                              >
                                {categorie.name}
                              </option>
                            ))}
                          </select>
                        </label>
                        <label className="productlabels">
                          Product Supplier :
                          <select
                            multiple={false}
                            value={EditProductSupplierID}
                            onChange={(event) =>
                              setEditProductSupplierID(event.target.value)
                            }
                            required
                          >
                            <option value="">Select a supplier</option>
                            {ProductSupplierA.map((supplier: any) => (
                              <option key={supplier.id} value={supplier.id}>
                                {supplier.name}
                              </option>
                            ))}
                          </select>
                        </label>
                        <label className="productlabels">
                          Model :
                          <input
                            type="text"
                            placeholder=""
                            value={EditProductModel}
                            onChange={(event) =>
                              setEditProductModel(event.target.value)
                            }
                            min={1}
                            required
                          ></input>
                        </label>

                        <label className="productlabels">
                          Method of Purchase:
                          <select
                            multiple={false}
                            value={EditedProductPurchasedMethod}
                            onChange={(event) =>
                              setEditedProductPurchasedMethod(
                                event.target.value
                              )
                            }
                            required
                          >
                            <option value="">
                              Select a Method of Purchase
                            </option>
                            <option value="direct purchase">
                              Direct Purchase
                            </option>
                            <option value="import">Import</option>
                          </select>
                        </label>

                        {/* <label className="productlabels">
                          Warehouse:
                          <select
                            multiple={false}
                            value={EditProductWarehouseID}
                            onChange={(event) =>
                              setEditProductWarehouseID(event.target.value)
                            }
                            required
                          >
                            <option value="">Select a Warehouse</option>
                            {ProductWarehouseA.map((Warehouse: any) => (
                              <option key={Warehouse.id} value={Warehouse.id}>
                                {Warehouse.name}
                              </option>
                            ))}
                          </select>
                        </label> */}

                        <label className="productlabels">
                          Prodct Image :
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <input
                              type="file"
                              accept="image/*"
                              onChange={handleUpdateImageChange}
                            />
                            {EditProductImage && (
                              <img
                                src={EditProductImage}
                                alt="Uploaded"
                                className="ProductPreviewImg"
                                style={{
                                  maxWidth: "200px",
                                  maxHeight: "200px",
                                }}
                              />
                            )}
                          </div>
                        </label>

                        {/* <label className="productlabels">
                          Product Documentation
                          <div>
                            <input
                              type="file"
                              accept=".pdf,.txt,.DOC,.DOCX,.CSV,.PPT,.PPTX,XLS,.XLSX"
                              onChange={handleDocumentChange}
                            />
                          </div>
                        </label>
                        {EditedProductDoc ? (
                          <a href={EditedProductDoc} download>
                            Preview Product doc
                          </a>
                        ) : (
                          <h5>No file to preview</h5>
                        )} */}

                        <label className="productlabels">
                          Product Description:
                          <textarea
                            placeholder="Detail Product Description"
                            value={EditedProductDescription}
                            onChange={(event) =>
                              setEditedProductDescription(event.target.value)
                            }
                          ></textarea>
                        </label>
                      </div>
                      <div className="ProductAuthDiv">
                        <label className="productlabels">
                          Created By:
                          <input
                            type="text"
                            placeholder=""
                            value={ProductCreatedBy}
                            readOnly
                          ></input>
                        </label>

                        <label className="productlabels">
                          Created At:
                          <input
                            type="text"
                            placeholder=""
                            value={ProductCreatedAt}
                            readOnly
                          ></input>
                        </label>

                        <label className="productlabels">
                          Last Updated By:
                          <input
                            type="text"
                            placeholder=""
                            value={ProductUpdatedBy}
                            readOnly
                          ></input>
                        </label>

                        <label className="productlabels">
                          Last Updated At:
                          <input
                            type="text"
                            placeholder=""
                            value={ProductUpdatedAt}
                            readOnly
                          ></input>
                        </label>

                        <div className="StockLevel">
                          <div>
                            <h3>Stock Level</h3>
                            <div className="StockColumn">
                              <h4>Warehouse</h4>
                              <h4>Quantity</h4>
                            </div>
                          </div>
                          {productWarehouseData.map((pw: any) => (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <label> {pw.Warehouse.name}</label>
                              <label> {pw.quantity}</label>
                            </div>
                          ))}
                        </div>

                        {/* {productWarehouseData.map((index: any, value: any) => {
                        <label key={index}>${value.id}</label>;
                      })} */}
                      </div>
                    </div>
                  </div>
                  <div className="ProductEditModalFooterDiv">
                    <button className="addbtn" type="submit">
                      Edit Product
                    </button>
                  </div>
                </form>
              </Spin>
            </Typography>
          </Box>
        </Modal>
      </div>
      {/*  */}
      <Spin spinning={loading}>
        <DataGrid
          rows={productData}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
            columns: {
              columnVisibilityModel: {
                // Hide columns status and traderName, the other columns will remain visible
                image: false,
                createdAt: false,
                createdBy: false,
                updatedAt: false,
                updatedBy: false,
                ProductCategorieId: false,
                // ProductCategorieUUID: false,
                ProductSubCategorieId: false,
                // ProductSubCategorieUUID: false,
                Product_Warehouses: false,
                SupplierId: false,
                WarehouseId: false,
                Warehouse: false,
                description: false,
                document: false,
                purchase_method: false,
              },
            },
          }}
          pageSizeOptions={[5, 10]}
          checkboxSelection
        />
      </Spin>
    </div>
  );
}
