import React from "react";
import axios from "axios";
import { sessionStorage } from "es-storage";
const token = sessionStorage.get("HS_token");
const current_user = sessionStorage.get("user_name");

const addSupplier = async (props: any) => {
  try {
    var req = await axios.post(
      `${process.env.REACT_APP_SUPPLIER_ADD_ONE}`,
      {
        name: props.Name,
        mobile: props.Mobile,
        email: props.Email,
        address: props.Address,
        CreatedBy: current_user,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    if ((err.response.status = 404)) {
      // const navigate = useNavigate();
      // alert(err);
      // navigate("/", { replace: true });
    }
    throw err;
  }
};

const getAllSupplier = async () => {
  try {
    var req = await axios.get(`${process.env.REACT_APP_SUPPLIER_GET_ALL}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return req;
  } catch (err: any) {
    //console.log(err);
    if ((err.response.status = 404)) {
      // const navigate = useNavigate();
      alert(err);
      // navigate("/", { replace: true });
    }
    throw err;
  }
};

const deleteSingeleSupplier = async (props: any) => {
  // alert(props.id)
  try {
    var req = await axios.delete(
      `${process.env.REACT_APP_SUPPLIER_DELETE_ONE}/${props.id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    console.log(err);
    if ((err.response.status = 404)) {
      // const navigate = useNavigate();
    //  alert(err);
      // navigate("/", { replace: true });
    }
    throw err;
  }
};

const updateSingeleSupplier = async (props: any) => {
  // alert(props.id)
  try {
    // alert(
    //   `ID==${props.ID} \n Name==${props.Name} \n Email==${props.Email} \n Address==${props.Address}`
    // );
    var req = await axios.put(
      `${process.env.REACT_APP_SUPPLIER_UPDATE_ONE}/${props.ID}`,
      {
        name: props.Name,
        mobile: props.Mobile,
        email: props.Email,
        address: props.Address,
        UpdatedBy: current_user,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    console.log(err);
    if ((err.response.status = 404)) {
      // const navigate = useNavigate();
      alert(err);
      // navigate("/", { replace: true });
    }
    throw err;
  }
};

export {
  addSupplier,
  getAllSupplier,
  updateSingeleSupplier,
  deleteSingeleSupplier,
};
