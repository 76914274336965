import React from "react";
import "./Documentations.css";

const Documentations = () => {
  return (
    <div className="Documentation">
      <h1>Documentation Page Comming Soon!</h1>
    </div>
  );
};

export default Documentations;
