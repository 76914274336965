import React, { useEffect, useState } from "react";
import "./Landing.css";
import { ToastContainer, toast } from "react-toastify";
//api methods
import { getAllTenantModule } from "../../API/moduleAPI";
//
//import { jwtDecode } from "jwt-decode";
import { sessionStorage } from "es-storage";
//
import {
  Authorization,
  CheckUserModuleAccess,
} from "../../middleware/authorizationMiddleware";
//
import { Routes, Route } from "react-router-dom";
import { NavLink } from "react-router-dom";
import ResponsiveAppBar from "./responsiveAppBar";
//pages
import UserMng from "../UserMng/UserMng";
import Inventory from "../Inventory/Inventory";
import Profile from "../Profile/Profile";
import Settings from "../Settings/Settings";
import CustomerMng from "../CustomerMng/CustomerMng";
import SupplierMng from "../SupplierMng/SupplierMng";
import Documentations from "../Documentations/Documentations";
import ProfileEdit from "../Profile/ProfileEdit/ProfileEdit";
import Discuss from "../Discuss/Discuss";
import LogHistory from "../LogHistory/LogHistory";
import Sales from "../Sales/Sales";
import Dashboard from "../Dashboard/Dashboard";
//icons
import discussionIMG from "../../assest/Discussion.png";
import usersIMG from "../../assest/modules/user.gif";
import inventoryIMG from "../../assest/modules/inventory.gif";
import customerIMG from "../../assest/modules/customer.gif";
import supplierIMG from "../../assest/modules/ship.gif";
import dashboardIMG from "../../assest/dashboard.png";
import salesIMG from "../../assest/sales.png";
import logIMG from "../../assest/log.png";

const rbacArray: any[] = sessionStorage.getObject("user_rbac");

const Landing = () => {
  //modules list state
  const [TenantModuleList, setTenantModuleList] = useState<any[]>([]);
  //
  const userRole = sessionStorage.get("user_role");
  const [isAdmin, setIsAdmin] = useState(false);
  //const token = sessionStorage.get("HS_token");
  // const decoded = jwtDecode(token);

  useEffect(() => {
    if (userRole === "Admin") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, []);
  //
  interface LocationState {
    MODULEID: any; // Replace 'any' with the actual type, e.g., number or string
  }
  //
  const GetTenantModules = async () => {
    try {
      var req: any = await getAllTenantModule();
      setTenantModuleList(req.data);
      console.log(req.data);

      //console.log("session=" + sessionStorage.getObject("user_rbac"));
      // console.log("session=" + sessionStorage.getObject("user_rbac").length);
    } catch (err: any) {
      console.log(err);
      //notify_Error(err.response.data);
    }
  };

  useEffect(() => {
    GetTenantModules();
  }, []);
  //
  //
  return (
    <div className="landing">
      <ToastContainer />
      <div className="AppBarDiv">
        <ResponsiveAppBar />
      </div>
      <div className="landingDisplay">
        <Routes>
          <Route
            path="/"
            element={
              // <div className="ModulesDiv">Administrator
              <div className="AppsContainer">
                {/* {Authorization("Discuss", sessionStorage.get("rbac_Discuss"), [
                  "Administrator",
                  "Moderator",
                  "Participant",
                  "Observer",
                ]) ? (
                  <NavLink to="discuss">
                    <div className="AppCard">
                      <img src={discussionIMG} alt="discussionIMG"></img>
                      <h3>Discuss</h3>
                    </div>
                  </NavLink>
                ) : // <div className="AppCard">Request The Admin for Log Modul</div>
                null} */}

                {/* {Authorization(
                  "Dashboard",
                  sessionStorage.get("rbac_Dashboard"),
                  ["Administrator", "Viewer"]
                ) ? (
                  <NavLink to="dashboard">
                    <div className="AppCard">
                      <img src={dashboardIMG} alt="dashboardIMG"></img>
                      <h3>Dashboard</h3>
                    </div>
                  </NavLink>
                ) : // <div className="AppCard">Request The Admin for Log Modul</div>
                null} */}
                {/* <NavLink to="usermng">
                  <div className="AppCard">
                    <img src={usersIMG} alt="usersIMG"></img>
                    <h3>Users</h3>
                  </div>
                </NavLink> */}

                {TenantModuleList.map((module: any) => (
                  <div key={module.id}>
                    {CheckUserModuleAccess(module.Module.id) ? (
                      <NavLink
                        to={module.Module.link}
                        state={{
                          moduleId: module.Module.id,
                          moduleLink: module.Module.link,
                        }}
                      >
                        <div className="AppCard">
                          <img
                            src={module.Module.iconUrl}
                            alt={module.Module.name}
                          ></img>
                          <h3>{module.Module.name}</h3>
                        </div>
                      </NavLink>
                    ) : // <div className="AppCard">Request The Admin for Log Modul</div>
                    null}
                  </div>
                ))}

                {/* {Authorization(
                  "Employee",
                  sessionStorage.get("rbac_Employee"),
                  [
                    "Administrator",
                    "Manager",
                    "Employee",
                    "TeamLeader",
                    "HR",
                    "Sales",
                  ]
                ) ? (
                  <NavLink to="usermng">
                    <div className="AppCard">
                      <img src={usersIMG} alt="usersIMG"></img>
                      <h3>Users</h3>
                    </div>
                  </NavLink>
                ) : // <div className="AppCard">Request The Admin for Log Modul</div>
                null} */}

                {/* {Authorization(
                  "Inventory",
                  sessionStorage.get("rbac_Inventory"),
                  [
                    "Administrator",
                    "HR",
                    "InventoryManager",
                    "Sales",
                    "WarehouseStaff",
                  ]
                ) ? (
                  <NavLink to="inventory">
                    <div className="AppCard">
                      <img src={inventoryIMG} alt="inventoryIMG"></img>
                      <h3>Inventory</h3>
                    </div>
                  </NavLink>
                ) : // <div className="AppCard">Request The Admin for Log Modul</div>
                null} */}

                {/* {Authorization(
                  "Customer",
                  sessionStorage.get("rbac_Customer"),
                  ["Administrator", "Sales", "CustomerSupport"]
                ) ? (
                  <NavLink to="customermng">
                    <div className="AppCard">
                      <img src={customerIMG} alt="customerIMG"></img>
                      <h3>Customers</h3>
                    </div>
                  </NavLink>
                ) : // <div className="AppCard">Request The Admin for Log Modul</div>
                null} */}

                {/* {Authorization("Support", sessionStorage.get("rbac_Customer"), [
                  "Administrator",
                  "ProcurementManager",
                  "InventoryManager",
                  "Finance",
                ]) ? (
                  <NavLink to="suppliermng">
                    <div className="AppCard">
                      <div>
                        <img src={supplierIMG} alt="supplierIMG"></img>
                        <h3>Suppliers</h3>
                      </div>
                    </div>
                  </NavLink>
                ) : // <div className="AppCard">Request The Admin for Log Modul</div>
                null} */}

                {/* {Authorization("Sales", sessionStorage.get("rbac_Sales"), [
                  "Administrator",
                  "SalesManager",
                  "SalesRep",
                ]) ? (
                  <NavLink to="sales">
                    <div className="AppCard">
                      <div>
                        <img src={salesIMG} alt="salesIMG"></img>
                        <h3>Sales</h3>
                      </div>
                    </div>
                  </NavLink>
                ) : null} */}

                {/* {userRole == "Admin" && (
                  <NavLink to="suppliermng">
                    <div className="AppCard">Log</div>
                  </NavLink>
                )} */}

                {/* {isAdmin ? (
                  <NavLink to="loghistory">
                    <div className="AppCard">
                      <img src={logIMG} alt="logIMG"></img>
                      <h3>Log</h3>
                    </div>
                  </NavLink>
                ) : // <div className="AppCard">Request The Admin for Log Modul</div>
                null} */}
              </div>
              // </div>
            }
          ></Route>
          <Route path="profile" element={<Profile />}></Route>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="setting/*" element={<Settings />} />
          <Route path="usermng/*" element={<UserMng />} />
          <Route path="inventory/*" element={<Inventory />} />
          <Route path="discuss" element={<Discuss />} />
          <Route path="customermng" element={<CustomerMng />} />
          <Route path="suppliermng" element={<SupplierMng />} />
          <Route path="document" element={<Documentations />} />
          <Route path="profile_edit" element={<ProfileEdit />} />
          <Route path="loghistory" element={<LogHistory />} />
          <Route path="sales/*" element={<Sales />} />
        </Routes>
      </div>
      {/* <Outlet /> */}
    </div>
  );
};

export default Landing;
