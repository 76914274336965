import React from "react";
import "./Setting2.css";

const Setting2 = () => {
  return (
    <div>
      <div>
        <h3>Create Role</h3>
        <form>
          <select>
            <option value="">Select Application</option>
            <option value="Employees">Employees</option>
            <option>Dashboard</option>
            <option>Inventory</option>
          </select>
        </form>
      </div>
    </div>
  );
};

export default Setting2;
