import React, { useState, useEffect } from "react";
import "./Transfer.css";
//ant design
import { Button } from "antd";
import { Spin } from "antd";
////react toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
////mui model
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
//mui grid table
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridSlots,
} from "@mui/x-data-grid";
//
//api

import { getAllWarehouse } from "../../../API/warehouseAPI";
import {
  getAllProductTransfer,
  moveProduct,
} from "../../../API/productTransferAPI";
import { getAllProduct } from "../../../API/productAPI";
import { getAllUser } from "../../../API/userAPI";

//

const styleNewTransferModel = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  // height: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

//interfaces
// Define interface for warehouse object
interface Product {
  id: number;
  product: object;
  name: string;
  address: string;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
}

interface User {
  id: number;
  fullName: string;
  address: string;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
}

interface ProductHistory {
  id: number;
  product: object;
  name: string;
  address: string;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
}

const Transfer = () => {
  //notification methods
  const notifyDefault = (msg: string) =>
    toast(msg || "Default!", {
      position: "top-right",
      theme: "colored",
    });
  const notifyInfo = (msg: string) =>
    toast.info(msg || "Info!", {
      position: "top-right",
      theme: "colored",
    });
  const notifySuccess = (msg: string) =>
    toast.success(msg || "Success!", {
      position: "top-right",
      theme: "colored",
    });
  const notifyWarning = (msg: string) =>
    toast.warn(msg || "Warning!", {
      position: "top-right",
      theme: "colored",
    });
  const notifyError = (msg: string) =>
    toast.error(msg || "Error!", {
      position: "top-right",
      theme: "colored",
    });
  //
  //spin state
  const [loading, setLoading] = React.useState<boolean>(false);
  //
  const [productData, setProductData] = useState<Product[]>([]);
  const [usersData, setusersData] = useState<User[]>([]);
  const [productTransferData, setProductTransferData] = useState<
    ProductHistory[]
  >([]);
  //new producttransfer mui modal state
  const [openNewTransfer, setOpenNewTransfer] = React.useState(false);
  const handleOpenNewTransfer = () => setOpenNewTransfer(true);
  const handleCloseNewTransfer = () => setOpenNewTransfer(false);
  //
  const [FromWarehouseID, setFromWarehouseID] = useState("");
  const [ToWarehouseID, setToWarehouseID] = useState("");
  const [MoveUserID, setMoveUserID] = useState("");
  const [ProductQuantity, setProductQuantity] = useState("");
  const [ProductID, setProductID] = useState("");
  const [ProductWarehouseID, setProductWarehouseID] = useState("");
  const [ProductWarehouseA, setProductWarehouseA] = useState([]);
  const ProductWarehouse: any = [];
  //
  const HandleMoveProduct = async (event: any) => {
    event.preventDefault();

    try {
      //
      setLoading(true);
      //
      var req = await moveProduct({
        PWID: ProductWarehouseID as unknown as number,
        ProductID: ProductID as unknown as number,
        FromWarehoseID: FromWarehouseID as unknown as number,
        ToWarehoseID: ToWarehouseID as unknown as number,
        Quanity: ProductQuantity as unknown as number,
        MoveByID: MoveUserID as unknown as number,
      });
      console.log(req);
      GetProductTransferHistory();
      // notify_success(req.data.msg);
      notifySuccess("Product Moved Successfuly");
    } catch (err: any) {
      //notifyError(`${err.response.data}\n Status Code ${err.status}`);
      console.log(err);
      //notifyError("Error during moving product");
      if (err.response.data) {
        notifyError(`${err.response.data}\n Status Code ${err.status}`);
      } else {
        notifyError("Error during moving product");
      }
    } finally {
      setLoading(false); // Set loading to false after request completion or error
    }
  };

  const GetProductTransferHistory = async () => {
    try {
      var req: any = await getAllProductTransfer();
      setProductTransferData(req.data);
      console.log(req.data);
    } catch (err: any) {
      console.log(err);
      if (err.response.data) {
        notifyError(`${err.response.data}\n Status Code ${err.status}`);
      } else {
        notifyError("Error During getting move history list");
      }
    }
  };

  useEffect(() => {
    GetProductTransferHistory();
  }, []);
  //

  const HandleGetWarehouses = async (e: any) => {
    e.preventDefault();
    try {
      const call = await getAllWarehouse();
      //console.log(call.data[0].id);
      const data: any[] = call.data;
      console.log(data.length);
      for (let i = 0; i < data.length; i++) {
        ProductWarehouse.push({
          id: data[i].id,
          name: data[i].name,
        });
      }
      console.log(ProductWarehouse);
      setProductWarehouseA(ProductWarehouse);
    } catch (err) {
      console.log(err);
    }
  };

  const HandleGetProducts = async (e: any) => {
    e.preventDefault();
    try {
      const call: any = await getAllProduct();
      //console.log(call.data[0].id);
      const data: any[] = call.data;
      setProductData(data);
    } catch (err) {
      console.log(err);
    }
  };

  const HandleGetUsers = async (e: any) => {
    e.preventDefault();
    try {
      const call: any = await getAllUser();
      //console.log(call.data[0].id);
      const data: any[] = call.data;
      setusersData(data);
    } catch (err) {
      console.log(err);
    }
  };

  //
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "reference", headerName: "Reference", width: 100 },
    {
      field: "ProductName",
      valueGetter: (value, row) => {
        return `${row.Product ? row.Product.name : ""}`;
      },
      headerName: "Product",
      width: 100,
    },
    {
      field: "FromWarehouseName",
      valueGetter: (value, row) => {
        return `${row.FromWarehouse ? row.FromWarehouse.name : ""}`;
      },
      headerName: "From",
      width: 100,
    },
    {
      field: "ToWarehouseName",
      valueGetter: (value, row) => {
        return `${row.ToWarehouse ? row.ToWarehouse.name : ""}`;
      },
      headerName: "To",
      width: 100,
    },
    { field: "quantity", headerName: "Quantity", width: 130 },
    {
      field: "ApprovedUserName",
      valueGetter: (value, row) => {
        return `${row.ApprovedUser ? row.ApprovedUser.fullName : ""}`;
      },
      headerName: "Approved By",
      width: 100,
    },
    {
      field: "MoveUserName",
      valueGetter: (value, row) => {
        return `${row.MoveUser ? row.MoveUser.fullName : ""}`;
      },
      headerName: "Moved By",
      width: 100,
    },
    // { field: "address", headerName: "Address", width: 130 },
    // { field: "createdBy", headerName: "Created By", width: 130 },
    { field: "createdAt", headerName: "Created At", width: 130 },
    // { field: "updatedAt", headerName: "Updated At", width: 130 },
  ];
  //

  return (
    <div className="Page">
      <ToastContainer />
      <div className="ActionDiv">
        <Button
          onClick={(event) => {
            handleOpenNewTransfer();
            HandleGetProducts(event);
            HandleGetWarehouses(event);
            HandleGetUsers(event);
          }}
        >
          New Transfer
        </Button>
      </div>
      <Modal
        open={openNewTransfer}
        onClose={handleCloseNewTransfer}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleNewTransferModel}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <h3>Product Transfer Form</h3>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Spin spinning={loading}>
              <form onSubmit={HandleMoveProduct} style={{ width: "100%" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label className="productlabels">
                        Product:
                        <select
                          multiple={false}
                          onChange={(event) => setProductID(event.target.value)}
                          required
                        >
                          <option value="">Select a Product</option>
                          {productData.map((product: any) => (
                            <option key={product.id} value={product.id}>
                              {product.name}
                            </option>
                          ))}
                        </select>
                      </label>

                      <label className="productlabels">
                        From:
                        <select
                          multiple={false}
                          onChange={(event) =>
                            setFromWarehouseID(event.target.value)
                          }
                          required
                        >
                          <option value="">Select a Warehouse</option>
                          {ProductWarehouseA.map((Warehouse: any) => (
                            <option key={Warehouse.id} value={Warehouse.id}>
                              {Warehouse.name}
                            </option>
                          ))}
                        </select>
                      </label>

                      <label className="productlabels">
                        To:
                        <select
                          multiple={false}
                          onChange={(event) =>
                            setToWarehouseID(event.target.value)
                          }
                          required
                        >
                          <option value="">Select a Warehouse</option>
                          {ProductWarehouseA.map((Warehouse: any) => (
                            <option key={Warehouse.id} value={Warehouse.id}>
                              {Warehouse.name}
                            </option>
                          ))}
                        </select>
                      </label>

                      <label className="productlabels">
                        Moved By:
                        <select
                          multiple={false}
                          onChange={(event) =>
                            setMoveUserID(event.target.value)
                          }
                          required
                        >
                          <option value="">Select a Warehouse</option>
                          {usersData.map(
                            (user: { id: number; fullName: string }) => (
                              <option key={user.id} value={user.id}>
                                {user.fullName}
                              </option>
                            )
                          )}
                        </select>
                      </label>

                      <label>
                        Quantity:
                        <input
                          type="number"
                          onChange={(event) =>
                            setProductQuantity(event.target.value)
                          }
                          min={1}
                        ></input>
                      </label>
                    </div>
                  </div>
                </div>

                <button className="addbtn" type="submit">
                  Submit
                </button>
              </form>
            </Spin>
          </Typography>
        </Box>
      </Modal>
      {/* Material ui warehose table */}
      <DataGrid
        rows={productTransferData}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
          columns: {
            columnVisibilityModel: {
              // Hide columns status and traderName, the other columns will remain visible
              id: false,
            },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
      />
      {/*  */}
    </div>
  );
};

export default Transfer;
