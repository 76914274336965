import React from "react";
import "./Discuss.css";

const Discuss = () => {
  return (
    <div className="Discuss">
      <div className="discuss_nav">
        <div>
          <button className="msg_btn">Start Message</button>
        </div>
        <div>
          <h3>Inbox</h3>
          <h3>General Channel</h3>
        </div>
      </div>
      <div className="discuss_display">
        <h3>Discuss display</h3>
      </div>
    </div>
  );
};

export default Discuss;
