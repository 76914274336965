import React from "react";
import axios from "axios";
import { sessionStorage } from "es-storage";
const token = sessionStorage.get("HS_token");
const current_user = sessionStorage.get("user_name");

// interface ProductList {
//   Quantity: number;
//   price: number;
//   ProductID: number;
// }

const addQuotation = async (props: any) => {
  var array = props.QuotationItems;
  console.log("passedData" + array);
  //console.log("passedData" + JSON.stringify(array, null, 2));
  // console.log("Selected Product List1: \n" + JSON.stringify(array));
  // const arrayAsString = [JSON.stringify(props.QuotationItems)];
  //const arrayAsString = JSON.stringify(props.QuotationItems);
  // const arrayParse = JSON.parse(arrayAsString);
  // console.log("size: " + arrayParse);
  //const array = props.QuotationItems;
  //const j = JSON.parse(array);
  //console.log("Selected Product List: \n" + j);

  try {
    var req = await axios.post(
      `${process.env.REACT_APP_QUOTATION_ADD_ONE}`,
      {
        quotationDate: props.QuotationDate,
        expiryDate: props.ExpiryDate,
        UntaxedAmount: props.UntaxedAmount,
        TaxAmount: props.TaxAmount,
        totalAmount: props.TotalAmount,
        salesPerson: current_user,
        CreatedBy: current_user,
        CustomerID: props.CustomerID,
        ProductID: props.ProductID,
        QuotationItems: array,
        QuotationStatus: props.QuotationStatus,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    if ((err.response.status = 404)) {
      // const navigate = useNavigate();
      alert(err);
      // navigate("/", { replace: true });
    }
    throw err;
  }
};

const getAllQuotation = async () => {
  try {
    var req = await axios.get(`${process.env.REACT_APP_QUOTATION_GET_ALL}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(req);
    return req;
  } catch (err: any) {
    //console.log(err);
    if ((err.response.status = 404)) {
      // const navigate = useNavigate();
      alert(err);
      // navigate("/", { replace: true });
    }
    throw err;
  }
};

const getAllApprovalQuotation = async () => {
  try {
    var req = await axios.get(
      `${process.env.REACT_APP_QUOTATION_GET_APPROVED}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(req);
    return req;
  } catch (err: any) {
    //console.log(err);
    if ((err.response.status = 404)) {
      // const navigate = useNavigate();
      alert(err);
      // navigate("/", { replace: true });
    }
    throw err;
  }
};

const approveQuotation = async (props: any) => {
  // alert(props.id)
  try {
    // alert(
    //   `ID==${props.ID} \n Name==${props.Name} \n Email==${props.Email} \n Address==${props.Address}`
    // );
    var req = await axios.put(
      `${process.env.REACT_APP_QUOTATION_APPROVE}/${props.ID}`,
      {
        status: props.Status,
        UpdatedBy: current_user,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    console.log(err);
    if ((err.response.status = 404)) {
      // const navigate = useNavigate();
      alert(err);
      // navigate("/", { replace: true });
    }
    throw err;
  }
};

const deleteSingeleQuotation = async (props: any) => {
  // alert(props.id)
  try {
    var req = await axios.delete(
      `${process.env.REACT_APP_QUOTATION_DELETE_ONE}/${props.id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    console.log(err);
    if ((err.response.status = 404)) {
      // const navigate = useNavigate();
      alert(err);
      // navigate("/", { replace: true });
    }
    throw err;
  }
};

export {
  addQuotation,
  getAllQuotation,
  getAllApprovalQuotation,
  approveQuotation,
  deleteSingeleQuotation,
};
