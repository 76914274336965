import * as React from "react";
import { useEffect, useState, useCallback } from "react";
//
////Access Controle Middle Wares
import {
  CheckModuleCreateAccess,
  CheckModuleEditAccess,
  CheckModuleDeleteAccess,
} from "../../../middleware/authorizationMiddleware";
//
//notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//mui
import { DataGrid, GridColDef } from "@mui/x-data-grid";
//ant design
import { Spin } from "antd";
//
import {
  getAllSupplier,
  deleteSingeleSupplier,
  updateSingeleSupplier,
} from "../../../API/supplierAPI";
//

//mui data grid
//
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import {
  // GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  // GridToolbarContainer,
  GridActionsCellItem,
  // GridEventListener,
  GridRowId,
  // GridRowModel,
  // GridRowEditStopReasons,
  // GridSlots,
} from "@mui/x-data-grid";
import { elGR } from "@mui/material/locale";
//

// Define interface for product object
interface User {
  id: number;
  name: string;
  // email: string;
  // createdAt: string;
  updatedAt: string;
  role: string;
}

//material ui modal
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
//

export default function SupplierTable(props: any) {
  //spin state
  const [loading, setLoading] = React.useState<boolean>(false);
  //
  //
  const [supplierData, setSupplierData] = useState<User[]>([]);
  //mui grid
  const [selectedRows, setSelectedRows] = useState<User[]>([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );
  //supplier form stats
  const [supplierID, setSupplierID] = useState(0);
  const [supplierName, setSupplierName] = useState("");
  const [supplierMobile, setSupplierMobile] = useState("");
  const [supplierEmail, setSupplierEmail] = useState("");
  const [supplierAddress, setSupplierAddress] = useState("");

  //for modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  //
  //
  //notification methods
  const notifyDefault = (msg: string) =>
    toast(msg || "Default!", {
      position: "top-right",
      theme: "colored",
    });
  const notifyInfo = (msg: string) =>
    toast.info(msg || "Info!", {
      position: "top-right",
      theme: "colored",
    });
  const notifySuccess = (msg: string) =>
    toast.success(msg || "Success!", {
      position: "top-right",
      theme: "colored",
    });
  const notifyWarning = (msg: string) =>
    toast.warn(msg || "Warning!", {
      position: "top-right",
      theme: "colored",
    });
  const notifyError = (msg: string) =>
    toast.error(msg || "Error!", {
      position: "top-right",
      theme: "colored",
    });
  //

  const GetSuppliers = useCallback(async () => {
    try {
      var req: any = await getAllSupplier();
      setSupplierData(req.data);
      console.log(supplierData);
    } catch (err: any) {
      console.log(err);
      notifyError(err.response.data);
    }
  }, []);

  useEffect(() => {
    GetSuppliers();
  }, [GetSuppliers]);

  //
  const HandleEdit = async (event: any) => {
    event.preventDefault();
    try {
      //
      // alert(
      //   `ID==${supplierID} \n Name==${supplierName} \n Email==${supplierEmail} \n Address==${supplierAddress}`
      // );
      //
      //
      setLoading(true);
      //
      const call = await updateSingeleSupplier({
        ID: supplierID,
        Name: supplierName,
        Mobile: supplierMobile,
        Email: supplierEmail,
        Address: supplierAddress,
      });
      console.log(call);
      GetSuppliers();
      notifySuccess("");
    } catch (err: any) {
      console.log(err);
      notifyError(err.response.data);
    } finally {
      //
      setLoading(false);
      //
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    //setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    //setSupplierID(id);
    const editedRow: any = supplierData.find((row) => row.id === id);
    handleOpen();
    setSupplierID(editedRow.id);
    setSupplierName(editedRow.name);
    setSupplierAddress(editedRow.address);
    setSupplierEmail(editedRow.email);
    setSupplierMobile(editedRow.mobile);
    // console.log(editedRow.id);
  };

  const handleSaveClick = (id: GridRowId) => () => {
    //setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => async () => {
    //setRows(rows.filter((row) => row.id !== id));
    //alert("delete");
    try {
      //
      setLoading(true);
      //
      const call = await deleteSingeleSupplier({ id: id });
      console.log(call);
      GetSuppliers();
      notifySuccess("");
    } catch (err: any) {
      console.log(err);
      // notifyError(err.response.data || err.response.data.name);
      if (err.response.data) {
        notifyError(err.response.data);
      }
      if (err.response.data.name) {
        notifyError(err.response.data.name);
      }
      //alert(err.response.data);
    } finally {
      //
      setLoading(false);
      //
    }
  };

  const handleCancelClick = (id: GridRowId) => () => {
    // setRowModesModel({
    //   ...rowModesModel,
    //   [id]: { mode: GridRowModes.View, ignoreModifications: true },
    // });
    // const editedRow = rows.find((row) => row.id === id);
    // if (editedRow!.isNew) {
    //   setRows(rows.filter((row) => row.id !== id));
    // }
  };
  //

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "name", headerName: "Name", width: 130 },
    { field: "mobile", headerName: "Phone", width: 130 },
    { field: "email", headerName: "Email", width: 130 },
    { field: "address", headerName: "Address", width: 130 },
    { field: "createdAt", headerName: "Created At", width: 130 },
    { field: "updatedAt", headerName: "Updated At", width: 130 },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          CheckModuleEditAccess(props.ModuleID) ? (
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />
          ) : (
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              // onClick={handleEditClick(id)}
              sx={{ color: "grey.500", opacity: 0.5 }} // Color for disabled Delete button
            />
          ),
          CheckModuleDeleteAccess(props.ModuleID) ? (
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
            />
          ) : (
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              className="textPrimary"
              sx={{ color: "grey.500", opacity: 0.5 }} // Color for disabled Delete button
            />
          ),
        ];
      },
    },
  ];

  //
  const csvData = [
    ["firstname", "lastname", "email"],
    ["Ahmed", "Tomi", "ah@smthing.co.com"],
    ["Raed", "Labes", "rl@smthing.co.com"],
    ["Yezzi", "Min l3b", "ymin@cocococo.com"],
  ];
  //

  return (
    <div style={{ height: "85vh", width: "100%" }}>
      <ToastContainer />
      <DataGrid
        rows={supplierData}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
          columns: {
            columnVisibilityModel: {
              // Hide columns status and traderName, the other columns will remain visible
              id: false,
            },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
      />

      {/* for modal */}
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <div className="ProductEditModalHeaderDiv">
                Supplier Edit Form
              </div>
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <Spin spinning={loading}>
                <form onSubmit={HandleEdit}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <label className="productlabels">
                          Supplier ID
                          <input
                            type="text"
                            placeholder=""
                            value={supplierID}
                            required
                            readOnly
                          ></input>
                        </label>

                        <label className="productlabels">
                          Supplier Name
                          <input
                            type="text"
                            placeholder=""
                            onChange={(event) =>
                              setSupplierName(event.target.value)
                            }
                            value={supplierName}
                          ></input>
                        </label>

                        <label className="productlabels">
                          Supplier Mobile
                          <input
                            type="tel"
                            placeholder=""
                            value={supplierMobile}
                            onChange={(event) =>
                              setSupplierMobile(event.target.value)
                            }
                          ></input>
                        </label>

                        <label className="productlabels">
                          Supplier Email
                          <input
                            type="email"
                            placeholder=""
                            value={supplierEmail}
                            onChange={(event) =>
                              setSupplierEmail(event.target.value)
                            }
                          ></input>
                        </label>

                        <label className="productlabels">
                          Supplier Address
                          <textarea
                            value={supplierAddress}
                            onChange={(event) =>
                              setSupplierAddress(event.target.value)
                            }
                          ></textarea>
                        </label>
                      </div>
                    </div>
                  </div>
                  <button className="addbtn" type="submit">
                    Edit Supplier
                  </button>
                </form>
              </Spin>
            </Typography>
          </Box>
        </Modal>
      </div>
      {/* modal */}
    </div>
  );
}
