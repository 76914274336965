import React, { useState } from "react";
import "./ProfileEdit.css";
//
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Input } from "antd";
//notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//
import { localStorage, sessionStorage } from "es-storage";
//
import { ChangePassword, ChangeAvater } from "../../../API/userAPI";
//

const ProfileEdit = () => {
  //
  const [OldPassword, setOldPassword] = useState("");
  const [NewPassword, setNewPassword] = useState("");
  const [ConfirmPassord, setConfirmPassord] = useState("");
  const [UserImage, setUserImage] = useState(sessionStorage.get("user_image"));
  //
  const notifyError = (msg: any) => toast.error(msg || "Error!");
  const notifySucess = () => toast("Sucess");

  const handelChanhePassword = async (event: any) => {
    event.preventDefault();
    try {
      const req = await ChangePassword({
        id: "1",
        Old_Password: OldPassword,
        New_Password: NewPassword,
        Confirm_Passord: ConfirmPassord,
      });
      console.log(req);
      notifySucess();
    } catch (err: any) {
      console.log(err);
      notifyError(err.response.data);
    }
  };
  //

  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Set the base64 string as the source of the image
        setUserImage(reader.result as string);
      };
      // Read the file as a data URL
      reader.readAsDataURL(file);
    }
  };

  const UpdateAvaterHandler = async (event: any) => {
    event.preventDefault();
    try {
      //
      // alert(
      //   `ID==${supplierID} \n Name==${supplierName} \n Email==${supplierEmail} \n Address==${supplierAddress}`
      // );
      //
      const call = await ChangeAvater({
        Image: UserImage,
      });
      console.log(call);
      // GetProducts();
      notifySucess();
    } catch (err: any) {
      console.log(err);
      notifyError(err.response.data);
    }
  };

  return (
    <div className="Profile_Edit">
      <ToastContainer />
      <h3>Profile Edit Page</h3>
      <div className="ChangeAvatar">
        <form
          onSubmit={UpdateAvaterHandler}
          style={{ display: "flex", flexDirection: "column" }}
        >
          {/* <input
            type="file"
            name="image"
            accept="image/*"
            multiple={false}
            onChange={(event) => handleFileChange(event)}
          /> */}
          <label>
            Profile Avater :{" "}
            <div>
              <input
                type="file"
                accept="image/*"
                multiple={false}
                onChange={handleImageChange}
              />
              {UserImage && (
                <img
                  src={UserImage}
                  alt="Uploaded"
                  style={{
                    maxWidth: "150px",
                    maxHeight: "150px",
                  }}
                />
              )}
            </div>
          </label>
          <button type="submit">Update Avater</button>
        </form>
      </div>
      <div className="ChangePassword">
        <h2>Change Password</h2>
        <form
          onSubmit={handelChanhePassword}
          style={{ display: "flex", flexDirection: "column" }}
        >
          {/* <input
            type="password"
            placeholder="Old Password"
            onChange={(event) => setOldPassword(event.target.value)}
            required
          ></input> */}
          <Input.Password
            placeholder="Old Password"
            onChange={(event) => setOldPassword(event.target.value)}
            required
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
          {/* <input
            type="password"
            placeholder="New Password"
            onChange={(event) => setNewPassword(event.target.value)}
            required
          ></input> */}
          <Input.Password
            placeholder="New Password"
            onChange={(event) => setNewPassword(event.target.value)}
            required
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
          {/* <input
            type="password"
            placeholder="Confirm Password"
            onChange={(event) => setConfirmPassord(event.target.value)}
            required
          ></input> */}
          <Input.Password
            placeholder="Confirm Password"
            onChange={(event) => setConfirmPassord(event.target.value)}
            required
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
          <button type="submit">Change</button>
        </form>
      </div>
    </div>
  );
};

export default ProfileEdit;
