import React from "react";
import "./Dashboard.css"

const Dashboard = () => {
  return (
    <div className="inventoryDashnoard">
      <h1>Inventory Dashboard Comming Soon!</h1>
    </div>
  );
};

export default Dashboard;
