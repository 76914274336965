import React from "react";
import axios from "axios";
import { sessionStorage } from "es-storage";
const token = sessionStorage.get("HS_token");
const current_user = sessionStorage.get("user_name");
const current_user_id = sessionStorage.get("user_id");

const addNonFixedProduct = async (props: any) => {
  try {
    var req = await axios.post(
      `${process.env.REACT_APP_NonFixed_PRODUCT_ADD_ONE}`,
      {
        name: props.Name,
        internalref: props.InternalRef,
        model: props.Model,
        brand_name: props.Brand_Name,
        purchase_method: props.Purchase_Method,
        document: props.Document,
        description: props.Description,
        image: props.Image,
        quantity: props.Quantity,
        cost_price: props.Cost_Price,
        sales_price: props.Sales_Price,
        supplier_id: props.Supplier_ID,
        warehouse_id: props.Warehouse_ID,
        productCategorie_id: props.ProductCategorie_ID,
        date_of_purchase: props.DateofPurchase,
        CreatedBy: current_user_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    if ((err.response.status = 404)) {
      // const navigate = useNavigate();
      alert(err);
      // navigate("/", { replace: true });
    }
    throw err;
  }
};

const getAllNonFixedProduct = async () => {
  try {
    const req = await axios.get(
      `${process.env.REACT_APP_NonFixed_PRODUCT_GET_ALL}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(req);
    return req;
  } catch (err: any) {
    console.log(err);
    if (err.response && err.response.status === 404) {
      alert("Products not found.");
      // You can navigate to another page if needed:
      // const navigate = useNavigate();
      // navigate("/", { replace: true });
    }
    return null; // Ensure a consistent return value on error
  }
};

// const updateSingeleProduct = async (props: any) => {
//   // alert(props.id)
//   try {
//     // alert(
//     //   `ID==${props.ID} \n Name==${props.Name} \n Email==${props.Email} \n Address==${props.Address}`
//     // );
//     var req = await axios.put(
//       `${process.env.REACT_APP_PRODUCT_UPDATE_ONE}/${props.ID}`,
//       {
//         internalref: props.InternalRef,
//         name: props.Name,
//         brand_name: props.Brand_Name,
//         document: props.Document,
//         description: props.Description,
//         image: props.Image,
//         quantity: props.Quantity,
//         cost_price: props.Cost_Price,
//         sales_price: props.Sales_Price,
//         supplier_id: props.Supplier_ID,
//         purchase_method: props.Purchase_Method,
//         productCategorie_id: props.ProductCategorie_ID,
//         warehouse_id: props.Warehouse_ID,
//         UpdatedBy: current_user,
//       },
//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       }
//     );
//     return req;
//   } catch (err: any) {
//     console.log(err);
//     if ((err.response.status = 404)) {
//       // const navigate = useNavigate();
//       alert(err);
//       // navigate("/", { replace: true });
//     }
//     throw err;
//   }
// };

// const deleteSingeleProduct = async (props: any) => {
//   // alert(props.id)
//   try {
//     var req = await axios.delete(
//       `${process.env.REACT_APP_PRODUCT_DELETE_ONE}/${props.id}`,
//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       }
//     );
//     return req;
//   } catch (err: any) {
//     console.log(err);
//     if ((err.response.status = 404)) {
//       // const navigate = useNavigate();
//       alert(err);
//       // navigate("/", { replace: true });
//     }
//     throw err;
//   }
// };

export {
  addNonFixedProduct,
  getAllNonFixedProduct,
  // updateSingeleProduct,
  // deleteSingeleProduct,
};
