import React from "react";
import { useState, useEffect } from "react";
import "./Sales.css";
//
import { Authorization } from "../../middleware/authorizationMiddleware";
//pages
import Quotation from "./Quotation/Quotation";
import Order from "./Order/Order";
//
import { localStorage, sessionStorage } from "es-storage";
//
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useNavigate } from "react-router-dom";
//
//mui
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
//

const Sales = () => {
  
  const navigate = useNavigate();
  
  const userRole = sessionStorage.get("user_role");
  const [isAdmin, setIsAdmin] = useState(false);
  
  useEffect(() => {
    if (userRole == "Admin") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, []);
  
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    //navigate("configuration");
    setAnchorEl(event.currentTarget);
    //navigate("configuration");
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  //
  //
  return (
    <div className="Sales">
      <div className="salesnavDiv">
        <h2>Sales</h2>

        <div className="inventoty_Menus">
          {/* {isAdmin ? (
            <Button
              onClick={(event) => {
                // handleOpen();
                // HandleGetSuppliers(event);
                navigate("./");
              }}
            >
              Quatation
            </Button>
          ) : // <div className="AppCard">Request The Admin for Log Modul</div>
          null} */}
          {Authorization("Sales", sessionStorage.get("rbac_Sales"), [
            "Administrator",
          ]) ? (
            <Button
              onClick={(event) => {
                // handleOpen();
                // HandleGetSuppliers(event);
                navigate("./");
              }}
            >
              Quatation
            </Button>
          ) : // <div className="AppCard">Request The Admin for Log Modul</div>
          null}

          {Authorization("Sales", sessionStorage.get("rbac_Sales"), [
            "Administrator",
          ]) ? (
            <Button
              onClick={(event) => {
                // handleOpen();
                // HandleGetSuppliers(event);
                navigate("order");
              }}
            >
              Order
            </Button>
          ) : // <div className="AppCard">Request The Admin for Log Modul</div>
          null}

          {Authorization("Sales", sessionStorage.get("rbac_Sales"), [
            "Administrator",
          ]) ? (
            <div>
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                Configurations
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={handleClose}>
                  <label
                    onClick={(event) => {
                      navigate("warehousemng");
                    }}
                  >
                    Warehouse Management
                  </label>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <label
                    onClick={(event) => {
                      navigate("productcategory");
                    }}
                  >
                    Product Categories
                  </label>
                </MenuItem>
              </Menu>
            </div>
          ) : // <div className="AppCard">Request The Admin for Log Modul</div>
          null}
        </div>
      </div>
      <div className="inventoryDisplay">
        <Routes>
          <Route index element={<Quotation />}></Route>
          <Route path="/order" element={<Order />} />
          {/* <Route path="/warehousemng" element={<WarehouseMng />} />
          <Route path="/productcategory" element={<ProductCategory />} /> */}
        </Routes>
      </div>
    </div>
  );
};

export default Sales;
