import React, { useState, useEffect } from "react";
import "./Order.css";
//api methods
import {
  addQuotation,
  getAllQuotation,
  getAllApprovalQuotation,
  approveQuotation,
} from "../../../API/quotationAPI";
import { getAllCustomer } from "../../../API/customerAPI";
import { getAllProduct } from "../../../API/productAPI";
//
//page/components
import NewQuotation from "./NewQuotation/NewQuotation";
import PDFGenerater from "./PDF/PDF";
//mui
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridSlots,
} from "@mui/x-data-grid";
import { Height } from "@mui/icons-material";

//

// Define interface for customer object
interface Customer {
  id: number;
  name: string;
  email: string;
  mobile: string;
  createdBy: string;
  updatedBy: string;
}

// Define interface for quotation object
interface Quotation {
  id: number;
  QuotationDate: Date;
  ExpiryDate: Date;
  TotalAmount: Number;
  SalesPerson: string;
  createdBy: string;
  updatedBy: string;
}
//material ui modal style
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
//

const Order = () => {
  //New Quotation state variables
  const [NewQuotationCustomer, setNewQuotationCustomer] = useState("");
  const [NewQuotationDate, setNewQuotationDate] = useState("");
  const [NewQuotationExpiryDate, setNewQuotationExpiryDate] = useState("");
  const [NewQuotationTotalPrice, setNewQuotationTotalPrice] = useState("");
  const [NewQuotationTotalStatus, setNewQuotationTotalStatus] = useState("");
  //Edit Quotation state variables
  const [EditQuotationID, SetEditQuotationID] = useState("");
  const [EditQuotationDate, setEditQuotationDate] = useState("");
  const [EditQuotationExpiryDate, setEditQuotationExpiryDate] = useState("");
  const [EditQuotationTotalPrice, setEditQuotationTotalPrice] = useState("");
  const [EditQuotationOrderList, setEditQuotationOrderList] = useState([]);
  //Customer Data
  const [CustomerID, setCustomerID] = useState("");
  const [CustomerName, setCustomerName] = useState("");
  const [CustomerEmail, setCustomerEmail] = useState("");
  const [CustomerPhone, setCustomerPhone] = useState("");
  //Product Data
  const [ProductID, setProductID] = useState("");
  const [ProductName, setProductName] = useState("");
  const [ProductPrice, setProductPrice] = useState("");
  const [QuotationProductArray, setQuotationProductArray] = useState([]);
  const QuotationProduct: any = [];
  //
  const [QuotationCustomterArray, setQuotationCustomterArray] = useState([]);
  const QuotationCustomter: any = [];
  //
  const [quotationData, setQuotationData] = useState<Quotation[]>([]);
  //mui grid
  const [selectedRows, setSelectedRows] = useState<Quotation[]>([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );
  //mui modal state for New Quotation
  const [openNewQuotation, setopenNewQuotation] = React.useState(false);
  const handleOpenNewQuotation = () => setopenNewQuotation(true);
  const handleCloseNewQuotation = () => setopenNewQuotation(false);
  //mui modal state for New Quotation
  const [openEditQuotation, setopenEditQuotation] = React.useState(false);
  const handleOpenEditQuotation = () => setopenEditQuotation(true);
  const handleCloseEditQuotation = () => setopenEditQuotation(false);
  //
  const [numProducts, setNumProducts] = useState(1);
  const [productsList, setProductsList] = useState([]);

  const handleNumProductsChange = (e: any) => {
    setNumProducts(parseInt(e.target.value));
  };

  const handleProductChange = (index: any, e: any) => {
    const newProducts = [...productsList];
    newProducts: [index] = e.target.value;
    setProductsList(newProducts);
  };
  // Custom functions
  const HandleAddQuotation = async (event: any) => {
    event.preventDefault();
    try {
      var req = await addQuotation({
        QuotationDate: NewQuotationDate,
        ExpiryDate: NewQuotationExpiryDate,
        TotalAmount: NewQuotationTotalPrice,
        CustomerID: NewQuotationCustomer,
        ProductID: ProductID,
      });
      console.log(req);
      //notify_success(req.data.msg);
    } catch (err: any) {
      //notify(err.response.data);
      console.log(err);
    }
  };

  const GetQutations = async () => {
    try {
      var req: any = await getAllApprovalQuotation();
      setQuotationData(req.data);
      console.log(req);
    } catch (err: any) {
      console.log(err);
      //notify_Error(err.response.data);
    }
  };

  useEffect(() => {
    GetQutations();
  }, []);

  const HandleGetCustomers = async (e: any) => {
    e.preventDefault();
    try {
      const call = await getAllCustomer();
      //console.log(call.data[0].id);
      // console.log(call);
      const data: any[] = call.data;
      //console.log(data.length);
      for (let i = 0; i < data.length; i++) {
        QuotationCustomter.push({
          id: data[i].id,
          name: data[i].name,
        });
      }
      //console.log(ProductSupplier);
      setQuotationCustomterArray(QuotationCustomter);
      console.log(QuotationCustomter);
    } catch (err) {
      console.log(err);
    }
  };

  const HandleGetProducts = async (e: any) => {
    e.preventDefault();
    try {
      const callPAPI: any = await getAllProduct();
      //console.log(call.data[0].id);
      // console.log(call);
      const data: any[] = callPAPI.data;
      //console.log(data.length);
      for (let i = 0; i < data.length; i++) {
        QuotationProduct.push({
          id: data[i].id,
          name: data[i].name,
        });
      }
      //console.log(ProductSupplier);
      setQuotationProductArray(QuotationProduct);
      //console.log(callPAPI.data);
    } catch (err) {
      console.log(err);
    }
  };

  const HandleQuotationApproval = async (event: any, status: string) => {
    event.preventDefault();
    try {
      //
      // alert(
      //   `ID==${supplierID} \n Name==${supplierName} \n Email==${supplierEmail} \n Address==${supplierAddress}`
      // );
      //
      const call = await approveQuotation({
        ID: EditQuotationID,
        Status: status,
      });
      console.log(call);
      // GetCustomers();
      // notifyDone();
    } catch (err: any) {
      console.log(err);
      // notifyErr(err.response.data);
    }
  };

  // Table Methods
  const handleEditClick = (id: GridRowId) => async (event: any) => {
    //setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    //setSupplierID(id);
    await HandleGetCustomers(event);
    await HandleGetProducts(event);
    const editedRow: any = quotationData.find((row) => row.id === id);
    handleOpenEditQuotation();
    SetEditQuotationID(editedRow.id);
    setEditQuotationDate(editedRow.QuotationDate);
    setEditQuotationExpiryDate(editedRow.ExpiryDate);
    setEditQuotationTotalPrice(editedRow.TotalAmount);
    setEditQuotationOrderList(editedRow.OrderList);
    console.log(editedRow.OrderList_PID);
    //set Customer Data
    setCustomerID(editedRow.Customer.id);
    setCustomerName(editedRow.Customer.name);
    setCustomerEmail(editedRow.Customer.email);
    setCustomerPhone(editedRow.Customer.mobile);

    //setSupplierMobile(editedRow.mobile);
    // console.log(editedRow.id);
  };

  const handleSaveClick = (id: GridRowId) => () => {
    //setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => async () => {
    //setRows(rows.filter((row) => row.id !== id));
    //alert("delete");
    // try {
    //   const call = await deleteSingeleSupplier({ id: id });
    //   console.log(call);
    //   GetSuppliers();
    //   notifyDone();
    // } catch (err: any) {
    //   console.log(err);
    //   notifyErr(err.response.data);
    // }
  };

  const handleCancelClick = (id: GridRowId) => () => {
    // setRowModesModel({
    //   ...rowModesModel,
    //   [id]: { mode: GridRowModes.View, ignoreModifications: true },
    // });
    // const editedRow = rows.find((row) => row.id === id);
    // if (editedRow!.isNew) {
    //   setRows(rows.filter((row) => row.id !== id));
    // }
  };
  //
  //  Table Column defination
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "CustomerId", headerName: "CustomerId", width: 130 },
    { field: "QuotationDate", headerName: "Quotation Date", width: 130 },
    { field: "ExpiryDate", headerName: "Expiry Date", width: 130 },
    { field: "TotalAmount", headerName: "Total Amount", width: 130 },
    { field: "SalesPerson", headerName: "Sales Person", width: 130 },
    { field: "Status", headerName: "Status", width: 130 },
    {
      field: "OrderList_PID",
      valueGetter: (value, row) => {
        // if (row.QuotationItems) {
        //   let array: { ProductID: 0 };
        //   array = row.QuotationItems.find((item: any) => item.id === row.id);
        //   return `${row.QuotationItems ? array.ProductID : ""}`;
        // } else {
        //   return `${""}`;
        // }
      },
      headerName: "Supplier",
      width: 100,
    },
    { field: "createdAt", headerName: "Created At", width: 130 },
    { field: "updatedAt", headerName: "Updated At", width: 130 },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  //
  return (
    <div>
      <div className="quotationMngActionDiv">
        <Button
          onClick={(event) => {
            handleOpenNewQuotation();
            HandleGetCustomers(event);
            HandleGetProducts(event);
          }}
        >
          New
        </Button>
      </div>
      {/* MUI DATA TABLE */}
      <DataGrid
        rows={quotationData}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
      />
      {/*  */}
      {/* New Quotation Model */}
      <Modal
        open={openNewQuotation}
        onClose={handleCloseNewQuotation}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {/* <div>
              <button onClick={() => PDFGenerater}>Generate Pdf</button>
            </div> */}
            New Quotation Form
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <form onSubmit={HandleAddQuotation} style={{ width: "100%" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label className="productlabels">
                      Customer:
                      <select
                        multiple={false}
                        onChange={(event) =>
                          setNewQuotationCustomer(event.target.value)
                        }
                      >
                        <option value="">Select a Customer</option>
                        {QuotationCustomterArray.map((customer: any) => (
                          <option key={customer.id} value={customer.id}>
                            {customer.name}
                          </option>
                        ))}
                      </select>
                    </label>

                    <label>
                      Number of Products:
                      <input
                        type="number"
                        value={numProducts}
                        onChange={handleNumProductsChange}
                      />
                    </label>

                    <div className="ProductTableDiv">
                      <label>Product List</label>
                      <table>
                        <tr>
                          <th>Product</th>
                          <th>Quantity</th>
                          <th>Unit Price</th>
                        </tr>
                        <tbody className="tbody">
                          {[...Array(numProducts)].map((_, index) => (
                            // <div key={index}>
                            //   <label>
                            //     Product {index + 1}:
                            //     <input
                            //       type="text"
                            //       value={productsList[index] || ""}
                            //       onChange={(e) => handleProductChange(index, e)}
                            //     />
                            //   </label>
                            //   <br />
                            // </div>
                            <th>
                              <td>
                                <select
                                  multiple={false}
                                  onChange={(event) =>
                                    setNewQuotationCustomer(event.target.value)
                                  }
                                >
                                  <option value="">Select a Customer</option>
                                  {QuotationCustomterArray.map(
                                    (customer: any) => (
                                      <option
                                        key={customer.id}
                                        value={customer.id}
                                      >
                                        {customer.name}
                                      </option>
                                    )
                                  )}
                                </select>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  placeholder="Unit Quantity"
                                />
                              </td>
                              <td>
                                <input type="text" placeholder="Unit Price" />
                              </td>
                            </th>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    {/* <label className="productlabels">
                      Product:
                      <select
                        multiple={false}
                        onChange={(event) => setProductID(event.target.value)}
                      >
                        <option value="">Select a Product</option>
                        {QuotationProductArray.map((product: any) => (
                          <option key={product.id} value={product.id}>
                            {product.name}
                          </option>
                        ))}
                      </select>
                    </label> */}

                    <label>
                      Quotation Date :
                      <input
                        type="date"
                        placeholder=""
                        onChange={(event) =>
                          setNewQuotationDate(event.target.value)
                        }
                        required
                      ></input>
                    </label>

                    <label>
                      Expire Date :
                      <input
                        type="date"
                        placeholder=""
                        onChange={(event) =>
                          setNewQuotationExpiryDate(event.target.value)
                        }
                        required
                      ></input>
                    </label>

                    <label>
                      Total Price :
                      <input
                        type="number"
                        placeholder=""
                        onChange={(event) =>
                          setNewQuotationTotalPrice(event.target.value)
                        }
                        required
                      ></input>
                    </label>
                  </div>
                </div>
              </div>
              <button className="addbtn" type="submit">
                Create Quotation
              </button>
            </form>
          </Typography>
        </Box>
      </Modal>
      {/*  */}
      {/* Edit Quotation Model */}
      <Modal
        open={openEditQuotation}
        onClose={handleCloseEditQuotation}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div>
                <PDFGenerater
                  PropsObj={{
                    CompanyName: "Yasart Engineering PLC",
                    CompanyEmail: "info@yasartengineering.com",
                    CompanyPhone: "+251 11 442 0024, +251 11 840 0237",
                    CompanyAddress:
                      "Nefas Silk Lafto Subcity Debrezeit Road \n In front of Nefas Silk Paint Factory, Dawi Bldg II, Ground Floor",
                    CustomerName: CustomerName,
                    CustomerEmail: CustomerEmail,
                  }}
                />
              </div>
              <button
                onClick={(event) => HandleQuotationApproval(event, "Approved")}
              >
                Approve
              </button>

              <button
                onClick={(event) => HandleQuotationApproval(event, "Rejected")}
              >
                Rejected
              </button>

              <button
                onClick={(event) => HandleQuotationApproval(event, "In Review")}
              >
                In Review
              </button>

              <button
                onClick={(event) =>
                  HandleQuotationApproval(event, "Returned for Edits")
                }
              >
                Need Edits
              </button>
            </div>
            Quotation Edit Form
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <form>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label>
                      Quotation Date :
                      <input
                        type="date"
                        placeholder=""
                        onChange={(event) =>
                          setEditQuotationDate(event.target.value)
                        }
                        required
                      ></input>
                    </label>

                    <label>
                      Expire Date :
                      <input
                        type="date"
                        placeholder=""
                        onChange={(event) =>
                          setEditQuotationExpiryDate(event.target.value)
                        }
                        required
                      ></input>
                    </label>

                    <label>
                      Total Price :
                      <input
                        type="number"
                        placeholder=""
                        onChange={(event) =>
                          setEditQuotationTotalPrice(event.target.value)
                        }
                        required
                      ></input>
                    </label>

                    {/* <label>
                      Supplier Address :{" "}
                      <textarea
                        onChange={(event) =>
                          setSupplierAddress(event.target.value)
                        }
                        required
                      ></textarea>
                    </label> */}
                  </div>
                </div>
              </div>
              <button className="addbtn" type="submit">
                Edit Quotation
              </button>
            </form>
          </Typography>
        </Box>
      </Modal>
      {/*  */}
    </div>
  );
};

export default Order;
