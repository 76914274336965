import React, { useState } from "react";
import "./Dashboard.css";
//api
//import { getAllStatistics } from "../../API/statisticsAPI";
//
import { sessionStorage } from "es-storage";

import UsersIcon from "../../assest/Dashboard/team.png";
import ProductsIcon from "../../assest/Dashboard/cubes.png";
import CustomersIcon from "../../assest/Dashboard/service.png";
import SuppliersIcon from "../../assest/Dashboard/supplier.png";
import QuotationsIcon from "../../assest/Dashboard/payment.png";

const Dashboard = () => {
  //
  const [CountEmployees, setCountEmployees] = useState(
    sessionStorage.get("stat_countEmployees")
  );
  const [CountCustomers, setCountCustomers] = useState(
    sessionStorage.get("stat_countCustomers")
  );
  const [CountOrders, setCountOrders] = useState(
    sessionStorage.get("stat_countOrders")
  );
  const [CountProducts, setCountProducts] = useState(
    sessionStorage.get("stat_countProducts")
  );
  const [CountQuotations, setCountQuotations] = useState(
    sessionStorage.get("stat_countQuotations")
  );
  const [CountSuppliers, setCountSuppliers] = useState(
    sessionStorage.get("stat_countSuppliers")
  );

  //
  // useEffect(() => {
  //   getAllStatistics();
  // }, []);
  //
  return (
    <div className="Dashboard">
      <div className="cardsContainer">
        <div className="Card">
          <img src={UsersIcon} alt="UsersIcon"></img>
          <label>Users</label>
          <label># {CountEmployees}</label>
        </div>

        <div className="Card">
          <img src={ProductsIcon} alt="ProductsIcon"></img>
          <label>Product</label>
          <label># {CountProducts}</label>
        </div>

        <div className="Card">
          <img src={CustomersIcon} alt="CustomersIcon"></img>
          <label>Customers</label>
          <label># {CountCustomers}</label>
        </div>

        <div className="Card">
          <img src={SuppliersIcon} alt="SuppliersIcon"></img>
          <label>Suppliers</label>
          <label># {CountSuppliers}</label>
        </div>

        <div className="Card">
          <img src={QuotationsIcon} alt="QuotationsIcon"></img>
          <label>Quotation</label>
          <label># {CountQuotations}</label>
        </div>

        <div className="Card">
          <img src={QuotationsIcon} alt="QuotationsIcon"></img>
          <label>Order</label>
          <label># {CountOrders}</label>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
