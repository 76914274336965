import React from "react";
import "./PDF.css";
import { useRef } from "react";
import { usePDF } from "react-to-pdf";
const PDFGenerater = (Props: any) => {
  const { toPDF, targetRef } = usePDF({ filename: "page.pdf" });
  //
  return (
    <div>
      <button onClick={() => toPDF()}>Generate PDF</button>
      <div
        className="quotationMain"
        style={{ position: "absolute", left: "-9999px" }}
        ref={targetRef}
      >
        {/* header */}
        <div className="header">
          <h1>{Props.PropsObj.CompanyName}</h1>
          <p>{Props.PropsObj.CompanyAddress}</p>
          <p>{Props.PropsObj.CompanyPhone}</p>
          <p>{Props.PropsObj.CompanyEmail}</p>
        </div>
        {/*  */}
        <div className="quotation">
          <h2>Quotation</h2>
          <div className="customer-info">
            <p>
              <strong>Customer:</strong> {Props.PropsObj.CustomerName}
            </p>
            <p>
              <strong>Email:</strong> {Props.PropsObj.CustomerEmail}
            </p>
            <p>
              <strong>Date:</strong> May 10, 2024
            </p>
          </div>
          <table className="items">
            <thead>
              <tr>
                <th>Item</th>
                <th>Description</th>
                <th>Quantity</th>
                <th>Unit Price</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Laptop</td>
                <td>Brand new laptop with latest specifications</td>
                <td>2</td>
                <td>$1000</td>
                <td>$2000</td>
              </tr>
              <tr>
                <td>Printer</td>
                <td>High-speed color printer</td>
                <td>1</td>
                <td>$500</td>
                <td>$500</td>
              </tr>
              <tr>
                <td>Desk</td>
                <td>Office desk with drawers</td>
                <td>1</td>
                <td>$300</td>
                <td>$300</td>
              </tr>
            </tbody>
          </table>
          <p>
            <strong>Total:</strong> $2800
          </p>
        </div>
        {/* footer */}
        <div className="footer">
          <p>Thank you for choosing Acme Company for your business needs.</p>
        </div>
        {/*  */}
      </div>
    </div>
  );
};

// const GeneratePDF = toPDF()

export default PDFGenerater;
