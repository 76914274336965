import React, { useState, useEffect } from "react";
import "./ProductSubCategorie.css";
//Ant Design
import { Skeleton } from "antd";
import { Spin } from "antd";
//react toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//api
import {
  addProductSubCategories,
  getAllProductSubCategories,
  deleteSingeleProductSubCategory,
} from "../../../API/productsubcategoriesAPI ";
import { getAllProductCategories } from "../../../API/productcategoriesAPI";
//mui model
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
//
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
//mui grid table
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridSlots,
} from "@mui/x-data-grid";
//
//material ui modal
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  // height: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
//
//

//
//

// Define interface for warehouse object
interface ProductSubcategorie {
  id: number;
  name: string;
  discription: string;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
}

const ProductSubCategorie = () => {
  //
  const notifyDefault = (msg: string) =>
    toast(msg || "Default!", {
      position: "top-right",
      theme: "colored",
    });
  const notifyInfo = (msg: string) =>
    toast.info(msg || "Info!", {
      position: "top-right",
      theme: "colored",
    });
  const notifySuccess = (msg: string) =>
    toast.success(msg || "Success!", {
      position: "top-right",
      theme: "colored",
    });
  const notifyWarning = (msg: string) =>
    toast.warn(msg || "Warning!", {
      position: "top-right",
      theme: "colored",
    });
  const notifyError = (msg: string) =>
    toast.error(msg || "Error!", {
      position: "top-right",
      theme: "colored",
    });
  //
  //spin state
  const [loading, setLoading] = React.useState<boolean>(false);
  //
  //
  const [productSubCategorieData, setProductSubCategorieData] = useState<
    ProductSubcategorie[]
  >([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );
  //
  const [ParentCategoryes, setParentCategoryes] = useState([]);
  //
  const [NewPSCParentID, setNewPSCParentID] = useState("");
  const [NewProductSubCategorieName, setNewProductSubCategorieName] =
    useState("");
  const [NewProductSubCategorieUUID, setNewProductSubCategorieUUID] =
    useState("");
  const [
    NewProductSubCategorieDiscription,
    setNewProductSubCategorieDiscription,
  ] = useState("");
  //new warehouse mui modal state
  const [openNewWarehouse, setOpenNewWarehouse] = React.useState(false);
  const handleOpenNewWarehouse = () => setOpenNewWarehouse(true);
  const handleCloseNewWareHouse = () => setOpenNewWarehouse(false);
  //custom methods
  const HandleAddProductCategorie = async (event: any) => {
    event.preventDefault();
    try {
      //
      setLoading(true);
      //
      var req = await addProductSubCategories({
        ParentID: NewPSCParentID,
        Name: NewProductSubCategorieName,
        UUID: NewProductSubCategorieUUID,
        Description: NewProductSubCategorieDiscription,
      });
      console.log(req);
      HandleGetProductSubCategories();
      notifySuccess(req.data.msg);
    } catch (err: any) {
      notifyError(err.response.data);
      console.log(err);
    } finally {
      //
      setLoading(false);
      //
    }
  };

  const HandleGetParentCategories = async () => {
    try {
      var req: any = await getAllProductCategories();
      setParentCategoryes(req.data);
      console.log(ParentCategoryes);
    } catch (err) {
      console.log(err);
    }
  };

  const HandleGetProductSubCategories = async () => {
    try {
      var req: any = await getAllProductSubCategories();
      setProductSubCategorieData(req.data);
      console.log(req.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    HandleGetProductSubCategories();
  }, []);
  //

  //mui
  //methods
  const handleEditClick = (id: GridRowId) => () => {
    //setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    //handleOpen();
  };

  const handleSaveClick = (id: GridRowId) => () => {
    //setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    // setRowModesModel({
    //   ...rowModesModel,
    //   [id]: { mode: GridRowModes.View, ignoreModifications: true },
    // });
    // const editedRow = rows.find((row) => row.id === id);
    // if (editedRow!.isNew) {
    //   setRows(rows.filter((row) => row.id !== id));
    // }
  };
  const handleDeleteClick = (id: GridRowId) => async () => {
    //setRows(rows.filter((row) => row.id !== id));
    //alert("delete");
    try {
      const call = await deleteSingeleProductSubCategory({ id: id });
      console.log(call);
      HandleGetProductSubCategories();
      notifySuccess(call.data);
    } catch (err: any) {
      console.log(err);
      if (err.response.data) {
        notifyError(err.response.data);
      }
      //
      if (err.response.data.name) {
        notifyError(err.response.data.name);
      }
    }
  };

  //
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "name", headerName: "Name", width: 130 },
    {
      field: "ProductCategorie",
      valueGetter: (value, row) => {
        return `${row.ProductCategorie ? row.ProductCategorie.name : ""}`;
      },
      headerName: "Parent",
      width: 150,
    },
    { field: "uuid", headerName: "UUID", width: 130 },
    {
      field: "ProductCategorieuuid",
      valueGetter: (value, row) => {
        return `${row.ProductCategorie ? row.ProductCategorie.uuid : ""}`;
      },
      headerName: "Parent UUID",
      width: 150,
    },
    { field: "discription", headerName: "Discription", width: 130 },
    { field: "createdBy", headerName: "Created By", width: 130 },
    { field: "createdAt", headerName: "Created At", width: 130 },
    { field: "updatedAt", headerName: "Updated At", width: 130 },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <div className="warehouse">
      <ToastContainer />
      <div className="ActionDiv">
        <Button
          onClick={(e) => {
            handleOpenNewWarehouse();
            HandleGetParentCategories();
          }}
        >
          New Sub Categories
        </Button>
      </div>
      <div className="warehouseDisplay"></div>
      {/* New warehouse mode */}
      <Modal
        open={openNewWarehouse}
        onClose={handleCloseNewWareHouse}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            New Product Sub Categorie Form
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Spin spinning={loading}>
              <form
                onSubmit={HandleAddProductCategorie}
                style={{ width: "100%" }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label>
                        Parent Category
                        <select
                          multiple={false}
                          onChange={(e) => setNewPSCParentID(e.target.value)}
                          required
                        >
                          <option value="">Select Parent Category</option>
                          {ParentCategoryes.map(
                            (parent: { id: number; name: string }) => (
                              <option key={parent.id} value={parent.id}>
                                {parent.name}
                              </option>
                            )
                          )}
                        </select>
                      </label>

                      <label className="productlabels">
                        Name:
                        <input
                          type="text"
                          placeholder="e.g. Submersible Pump"
                          onChange={(event) =>
                            setNewProductSubCategorieName(event.target.value)
                          }
                          required
                        ></input>
                      </label>

                      <label className="productlabels">
                        UUID:
                        <input
                          type="text"
                          placeholder="e.g. 02"
                          onChange={(event) =>
                            setNewProductSubCategorieUUID(event.target.value)
                          }
                          maxLength={2}
                          required
                        ></input>
                      </label>

                      <label className="productlabels">
                        Description :
                        <textarea
                          placeholder="Product Categorie Discription"
                          onChange={(event) =>
                            setNewProductSubCategorieDiscription(
                              event.target.value
                            )
                          }
                        ></textarea>
                      </label>

                      {/* <label>
                        Supplier Address :{" "}
                        <textarea
                          onChange={(event) =>
                            setSupplierAddress(event.target.value)
                          }
                          required
                        ></textarea>
                      </label> */}
                    </div>
                  </div>
                </div>

                <button className="addbtn" type="submit">
                  Add
                </button>
              </form>
            </Spin>
          </Typography>
        </Box>
      </Modal>
      {/*  */}
      {/* Material ui warehose table */}
      <DataGrid
        rows={productSubCategorieData}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
          columns: {
            columnVisibilityModel: {
              // Hide columns status and traderName, the other columns will remain visible
              id: false,
            },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
      />
    </div>
  );
};

export default ProductSubCategorie;
