import React from "react";
import axios from "axios";
import { sessionStorage } from "es-storage";
const token = sessionStorage.get("HS_token");
const current_user_id = sessionStorage.get("user_id");
const current_user = sessionStorage.get("user_name");
//

const addUser = async (props: any) => {
  try {
    const token = sessionStorage.get("HS_token");
    var req = await axios.post(
      `${process.env.REACT_APP_USER_ADD_ONE}`,
      {
        name: props.Name,
        email: props.Email,
        phone: props.Phone,
        password: props.Password,
        role: props.Role,
        RBAC: props.RBAC,
        CreatedBy: props.CreatedBy,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    console.log(err);
    if ((err.response.status = 404)) {
      // const navigate = useNavigate();
      //alert(err);
      // navigate("/", { replace: true });
    }
    throw err;
  }
};

const getAllUser = async () => {
  try {
    var req = await axios.get(`${process.env.REACT_APP_USER_GET_ALL}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(req);
    return req;
  } catch (err: any) {
    if ((err.response.status = 404)) {
      // const navigate = useNavigate();
      //alert(err);
      // navigate("/", { replace: true });
    }
    console.log(err);
  }
};

const deleteSingeleUser = async (props: any) => {
  // alert(props.id)
  try {
    var req = await axios.delete(
      `${process.env.REACT_APP_USER_DELETE_ONE}/${props.id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    console.log(err);
    if ((err.response.status = 404)) {
      // const navigate = useNavigate();
      alert(err);
      // navigate("/", { replace: true });
    }
    throw err;
  }
};

const ChangePassword = async (props: any) => {
  //
  if (props.New_Password !== props.Confirm_Passord) {
    alert("Error While Confiremetion");
  } else {
    try {
      var req = await axios.put(
        `${process.env.REACT_APP_USER_CHANGE_PASSWORD}/${current_user_id}`,
        {
          OldPassword: props.Old_Password,
          NewPassword: props.New_Password,
          UpdatedBy: current_user,
        }, // Empty object as the second parameter for the request body
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(req);
      return req;
    } catch (err: any) {
      console.log(err);
      alert(err);
      throw err;
    }
  }
};

const ChangeAvater = async (props: any) => {
  //alert(current_user_id);
  try {
    // alert(
    //   `ID==${props.ID} \n Name==${props.Name} \n Email==${props.Email} \n Address==${props.Address}`
    // );
    var req = await axios.put(
      `${process.env.REACT_APP_USER_CHANGE_AVATER}/${current_user_id}`,
      {
        image: props.Image,
        UpdatedBy: current_user,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    console.log(err);
    // if ((err.response.status = 404)) {
    //   // const navigate = useNavigate();
    //   alert("Invalid Session");
    //   // navigate("/", { replace: true });
    // }
    alert(err);
    throw err;
  }
};

const UpdateUserRBAC = async (props: any) => {
  alert(props.UserId);
  try {
    alert(
      `Employee==${props.Employee} \n Dashboard==${props.Dashboard} \n Discuss==${props.Discuss} \n Inventory==${props.Inventory}`
    );
    var req = await axios.put(
      `${process.env.REACT_APP_USER_CHANGE_RBAC}/${props.UserId}`,
      {
        Employee: props.Employee,
        Dashboard: props.Dashboard,
        Discuss: props.Discuss,
        Inventory: props.Inventory,
        Customer: props.Customer,
        Supplier: props.Supplier,
        Sales: props.Sales,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    console.log(err);
    // if ((err.response.status = 404)) {
    //   // const navigate = useNavigate();
    //   alert("Invalid Session");
    //   // navigate("/", { replace: true });
    // }
    alert(err);
    throw err;
  }
};

export {
  getAllUser,
  addUser,
  deleteSingeleUser,
  ChangePassword,
  ChangeAvater,
  UpdateUserRBAC,
};
