import React from "react";
import axios from "axios";
import { sessionStorage } from "es-storage";
const token = sessionStorage.get("HS_token");
const current_user = sessionStorage.get("user_name");

const getQuotationItems = async (QuotationID: Number) => {
  try {
    var req = await axios.get(
      `${process.env.REACT_APP_QUOTATION_GET_Related}/${QuotationID}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(req);
    return req;
  } catch (err: any) {
    //console.log(err);
    if ((err.response.status = 404)) {
      // const navigate = useNavigate();
      alert(err);
      // navigate("/", { replace: true });
    }
    throw err;
  }
};

export { getQuotationItems };
