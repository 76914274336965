import React from "react";
import "./Settings.css";
// import { useLocation } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import { useNavigate } from "react-router-dom";
//pages
import Setting1 from "./Setting1/Setting1";
import Setting2 from "./Setting2/Setting2";
//icont

const Settings = () => {
  //
  const navigate = useNavigate();
  // Use useLocation hook to get the current location
  // const location = useLocation();
  return (
    <div className="settings">
      <div className="settingNav">
        <ul>
          <li
            onClick={(e) => {
              navigate("./");
            }}
          >
            <span className="icon icon1"></span>
            General
          </li>
          <li
            onClick={(e) => {
              navigate("Setting2");
            }}
          >
            <span className="icon icon1"></span>
            RBAC
          </li>
        </ul>
      </div>
      <div className="settingDisplay">
        <Routes>
          <Route index path="/" element={<Setting1 />}></Route>
          <Route path="Setting2" element={<Setting2 />} />
        </Routes>
      </div>
    </div>
  );
};

export default Settings;
