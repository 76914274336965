import * as React from "react";
import "./LogHistory.css";
import { useEffect, useState } from "react";
//
// //notification
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// //mui
import { DataGrid, GridColDef } from "@mui/x-data-grid";
// //
import { getAllLogs } from "../../API/logAPI";
//

//mui data grid
//
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridSlots,
} from "@mui/x-data-grid";
//

// Define interface for product object
interface Log {
  id: any;
  Date: string;
  Method: string;
  Path: string;
  IP: string;
  Host_Name: string;
  Protocol: string;
}

//material ui modal
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
//

export default function LogHistory() {
  const [supplierData, setSupplierData] = useState<Log[]>([]);
  //mui grid
  const [selectedRows, setSelectedRows] = useState<Log[]>([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );

  //for modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  //
  //
  // const notifyErr = (msg: any) => toast.error(msg || "Error when delete!");
  // const notifyDone = () => toast("Product deleted");
  // //
  // const notify_Error = (message: any) =>
  //   toast.error(`error! \n ${message}`, {
  //     position: "top-center",
  //     theme: "dark",
  //   });
  // const notify_success = (message: any) =>
  //   toast.success(`Message: \n ${message}`, {
  //     position: "top-center",
  //     theme: "colored",
  //   });
  // //

  const GetSuppliers = async () => {
    try {
      var req: any = await getAllLogs();
      setSupplierData(req.data);
      console.log(supplierData);
    } catch (err: any) {
      console.log(err);
      // notify_Error(err.response.data);
    }
  };

  useEffect(() => {
    GetSuppliers();
  }, []);

  //
  const HandleEdit = async (event: any) => {
    event.preventDefault();
    // try {
    //   //
    //   // alert(
    //   //   `ID==${supplierID} \n Name==${supplierName} \n Email==${supplierEmail} \n Address==${supplierAddress}`
    //   // );
    //   //
    //   const call = await updateSingeleSupplier({
    //     ID: supplierID,
    //     Name: supplierName,
    //     Mobile: supplierMobile,
    //     Email: supplierEmail,
    //     Address: supplierAddress,
    //   });
    //   console.log(call);
    //   GetSuppliers();
    //   notifyDone();
    // } catch (err: any) {
    //   console.log(err);
    //   notifyErr(err.response.data);
    // }
  };

  const handleEditClick = (id: GridRowId) => () => {
    //setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    //setSupplierID(id);
    // const editedRow: any = supplierData.find((row) => row.id === id);
    // handleOpen();
    // setSupplierID(editedRow.id);
    // setSupplierName(editedRow.name);
    // setSupplierAddress(editedRow.address);
    // setSupplierEmail(editedRow.email);
    // setSupplierMobile(editedRow.mobile);
    // console.log(editedRow.id);
  };

  const handleSaveClick = (id: GridRowId) => () => {
    //setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => async () => {
    //setRows(rows.filter((row) => row.id !== id));
    //alert("delete");
    // try {
    //   const call = await deleteSingeleSupplier({ id: id });
    //   console.log(call);
    //   GetSuppliers();
    //   notifyDone();
    // } catch (err: any) {
    //   console.log(err);
    //   notifyErr(err.response.data);
    // }
  };

  const handleCancelClick = (id: GridRowId) => () => {
    // setRowModesModel({
    //   ...rowModesModel,
    //   [id]: { mode: GridRowModes.View, ignoreModifications: true },
    // });
    // const editedRow = rows.find((row) => row.id === id);
    // if (editedRow!.isNew) {
    //   setRows(rows.filter((row) => row.id !== id));
    // }
  };
  //

  // Example function to generate unique IDs for rows
  function generateRowId(): string {
    // Implement your logic to generate unique IDs here
    return Math.random().toString(36).substring(7);
  }

  const columns: GridColDef[] = [
    {
      field: "ID",
      headerName: "ID",
      width: 70,
    },
    { field: "Date", headerName: "Date", width: 230 },
    { field: "Method", headerName: "Method", width: 200 },
    { field: "IP", headerName: "IP", width: 200 },
    { field: "Host_Name", headerName: "Host_Name", width: 200 },
    { field: "Protocol", headerName: "Protocol", width: 200 },
    // {
    //   field: "actions",
    //   type: "actions",
    //   headerName: "Actions",
    //   width: 100,
    //   cellClassName: "actions",
    //   getActions: ({ id }) => {
    //     const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

    //     if (isInEditMode) {
    //       return [
    //         <GridActionsCellItem
    //           icon={<SaveIcon />}
    //           label="Save"
    //           sx={{
    //             color: "primary.main",
    //           }}
    //           onClick={handleSaveClick(id)}
    //         />,
    //         <GridActionsCellItem
    //           icon={<CancelIcon />}
    //           label="Cancel"
    //           className="textPrimary"
    //           onClick={handleCancelClick(id)}
    //           color="inherit"
    //         />,
    //       ];
    //     }

    //     return [
    //       <GridActionsCellItem
    //         icon={<EditIcon />}
    //         label="Edit"
    //         className="textPrimary"
    //         onClick={handleEditClick(id)}
    //         color="inherit"
    //       />,
    //       <GridActionsCellItem
    //         icon={<DeleteIcon />}
    //         label="Delete"
    //         onClick={handleDeleteClick(id)}
    //         color="inherit"
    //       />,
    //     ];
    //   },
    // },
  ];

  //
  // const csvData = [
  //   ["firstname", "lastname", "email"],
  //   ["Ahmed", "Tomi", "ah@smthing.co.com"],
  //   ["Raed", "Labes", "rl@smthing.co.com"],
  //   ["Yezzi", "Min l3b", "ymin@cocococo.com"],
  // ];
  //

  return (
    <div style={{ height: "85vh", width: "100%" }}>
      {/* <ToastContainer /> */}
      <DataGrid
        rows={supplierData}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
      />
    </div>
  );
}
