import React from "react";
import "./Department.css";

const Department = () => {
  return (
    <div className="UserDepartment">
      <div className="departmentsContainer">
        <div className="departmentCard">
          <h2>Administration</h2>
          <label className="quantyty">Users #1</label>
        </div>
        <div className="departmentCard">
          <h2>Research & Development</h2>
          <label className="quantyty">Users #1</label>
        </div>
        <div className="departmentCard">
          <h2>Sales</h2>
          <label className="quantyty">Users #1</label>
        </div>
        <div className="departmentCard">
          <h2>HR</h2>
          <label className="quantyty">Users #1</label>
        </div>
        <div className="departmentCard">
          <h2>Work Shop</h2>
          <label className="quantyty">Users #1</label>
        </div>
        <div className="departmentCard">
          <h2>Department 1</h2>
          <label className="quantyty">Users #1</label>
        </div>
      </div>
    </div>
  );
};

export default Department;
