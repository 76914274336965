import React, { useState, useEffect } from "react";
import "./NonFixed.css";
//ant design
import type { RadioChangeEvent } from "antd";
import { Radio, Button } from "antd";
import { Tooltip, Popover } from "antd";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Skeleton } from "antd";
//material ui
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridSlots,
} from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
//notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//
import { localStorage, sessionStorage } from "es-storage";
//midewares
import { Authorization } from "../../../middleware/authorizationMiddleware";
//api controllers
import {
  addNonFixedProduct,
  getAllNonFixedProduct,
} from "../../../API/nonFixedProductAPI";
import { getAllSupplier } from "../../../API/supplierAPI";
//

const newNonFixedProductStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface Product {
  id: number;
  internalReference: string;
  productName: string;
  brandName: string;
  quantity: number;
  datePurchase: string;
  costPrice: number;
  supplierId: number;
  createdAt: string;
  updatedAt: string;
  updatedBy: string;
}

const NONFIXED = () => {
  //mui states
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );
  const [openNewNonFixedProductModal, setopenNewNonFixedProductModal] =
    React.useState(false);
  const handleOpenNewNonFixedProductModal = () =>
    setopenNewNonFixedProductModal(true);
  const handleCloseNewNonFixedProductModal = () =>
    setopenNewNonFixedProductModal(false);
  //
  const [rows, setRows] = useState<Product[]>([]);
  //New Product States
  const [NewProductInternalRef, setNewProductInternalRef] = useState("");
  const [NewProductName, setNewProductName] = useState("");
  const [NewProducBrandtName, setNewProductBrandName] = useState("");
  const [NewProductDescription, setNewProductDescription] = useState("");
  const [NewProductQuantity, setNewProductQuantity] = useState("");
  const [NewProductSupplierID, setNewProductSupplierID] = useState("");
  const [ProductSupplierA, setProductSupplierA] = useState([]);
  const ProductSupplier: any = [];
  const [NewProductDateofPurchase, setNewProductDateofPurchase] = useState("");
  const [NewProductCostPrice, setNewProductCostPrice] = useState("");
  const [NewProductWarehouse, setNewProductWarehouse] = useState("");

  //materil ui
  //

  const handleEditClick = (id: GridRowId) => async (event: any) => {
    //setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    //setEditID(id.toString);
    //event.preventDefault();
    //const event = new Event;

    await HandleGetSuppliers(event);
    // await HandleGetWarehouses(event);
    // await HandleGetProductCategories(event);
    const editedRow: any = rows.find((row) => row.id === id);

    // handleOpen();

    // setProductID(editedRow.id);
    // setProductName(editedRow.name);
    // setEditProductInternalRef(editedRow.internalref);
    // setEditProductBrand(editedRow.brand_name);
    // setEditProductQuantity(editedRow.quantity);
    // setEditedProductPurchasedMethod(editedRow.purchase_method);
    // setEditProductCostPrice(editedRow.cost_price);
    // setEditProductSalePrice(editedRow.sales_price);
    // setProductImage(editedRow.image);
    // setEditedProductDoc(editedRow.document);
    // setEditProductCategorie(editedRow.ProductCategorieId);
    // setEditProductSupplierID(editedRow.SupplierId);
    // setEditProductWarehouseID(editedRow.WarehouseId);
    // //setProductSupplierA(editedRow.SupplierId);
    // setEditedProductDescription(editedRow.description);
    // //auther
    // setProductCreatedBy(editedRow.createdBy);
    // setProductCreatedAt(editedRow.createdAt);
    // setProductUpdatedBy(editedRow.updatedBy);
    // setProductUpdatedAt(editedRow.updatedAt);
    //
    // if (editedRow.document) {
    //   setProductDocBadge(1);
    // } else {
    //   setProductDocBadge(0);
    // }
    // if (editedRow.quantity) {
    //   setProductInventoryBadge(editedRow.quantity);
    // } else {
    //   setProductInventoryBadge(0);
    // }
    //

    // handleOpen();
  };

  const handleSaveClick = (id: GridRowId) => () => {
    //setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => async () => {
    //setRows(rows.filter((row) => row.id !== id));
    //alert("delete");
    // try {
    //   const call = await deleteSingeleProduct({ id: id });
    //   console.log(call);
    //   GetProducts();
    //   notifyDone();
    // } catch (err: any) {
    //   console.log(err);
    //   notifyErr(err.response.data);
    // }
  };

  const handleCancelClick = (id: GridRowId) => () => {
    // setRowModesModel({
    //   ...rowModesModel,
    //   [id]: { mode: GridRowModes.View, ignoreModifications: true },
    // });
    // const editedRow = rows.find((row) => row.id === id);
    // if (editedRow!.isNew) {
    //   setRows(rows.filter((row) => row.id !== id));
    // }
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "	internalReference",
      headerName: "Internal Ref",
      width: 150,
      // editable: true,
    },
    {
      field: "productName",
      headerName: "Name",
      width: 150,
      // editable: true,
    },
    {
      field: "brandName",
      headerName: "Brand",
      type: "number",
      width: 110,
      // editable: true,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      // type: "number",
      width: 110,
      // editable: true,
    },
    {
      field: "datePurchase",
      headerName: "Date of Purchase",
      // type: "number",
      width: 110,
      // editable: true,
    },
    {
      field: "costPrice",
      headerName: "Cost Price",
      // type: "number",
      width: 110,
      // editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,

          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  //

  //Custom functions

  //notification
  const notifyDefault = (msg: string) =>
    toast(msg || "Default!", {
      position: "top-right",
      theme: "colored",
    });
  const notifyInfo = (msg: string) =>
    toast.info(msg || "Info!", {
      position: "top-right",
      theme: "colored",
    });
  const notifySuccess = (msg: string) =>
    toast.success(msg || "Success!", {
      position: "top-right",
      theme: "colored",
    });
  const notifyWarning = (msg: string) =>
    toast.warn(msg || "Warning!", {
      position: "top-right",
      theme: "colored",
    });
  const notifyError = (msg: string) =>
    toast.error(msg || "Error!", {
      position: "top-right",
      theme: "colored",
    });
  //

  const handleAddProduct = async (event: any) => {
    event.preventDefault();

    try {
      //alert(NewProductWarehouseID);
      //set suppliers
      //
      var req = await addNonFixedProduct({
        Name: NewProductName,
        InternalRef: NewProductInternalRef,
        // Model: NewProductModel,
        Brand_Name: NewProducBrandtName,
        // Purchase_Method: NewProductPurchasedMethod,
        // Document: NewProductDocument,
        Description: NewProductDescription,
        // Image: NewProductImage,
        Quantity: NewProductQuantity,
        Cost_Price: NewProductCostPrice,
        // Sales_Price: NewProductPrice,
        Supplier_ID: NewProductSupplierID,
        Warehouse_ID: NewProductWarehouse,
        DateofPurchase: NewProductDateofPurchase,
        // ProductCategorie_ID: NewProductCategorieID,
      });
      console.log(req);
      GetProducts();
      notifySuccess(req.data);
    } catch (err: any) {
      console.log(err);
      notifyError(err.response.data);
    }
  };

  const HandleGetSuppliers = async (e: any) => {
    e.preventDefault();
    try {
      const call = await getAllSupplier();
      //console.log(call.data[0].id);
      const data: any[] = call.data;
      console.log(data.length);
      for (let i = 0; i < data.length; i++) {
        ProductSupplier.push({
          id: data[i].id,
          name: data[i].name,
        });
      }
      console.log(ProductSupplier);
      setProductSupplierA(ProductSupplier);
    } catch (err) {
      console.log(err);
    }
  };

  const GetProducts = async () => {
    try {
      const req: any = await getAllNonFixedProduct();

      if (!req?.data || req.data.length === 0) {
        console.log("No products found.");
        setRows([]); // Set an empty array if no products are found
        console.log(rows);
        // Optionally, you can notify the user here
      } else {
        setRows(req.data);
        console.log("products found");
        console.log(rows.length);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // const GetProducts = async () => {
  //   try {
  //     var req: any = await getAllNonFixedProduct();
  //     setproductData(
  //       req.data.map((product: any, index: number) => ({
  //         ...product,
  //         id: index + 1, // Ensure each product has a unique ID
  //       }))
  //     );
  //     console.log(req.data);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  useEffect(() => {
    GetProducts();
  }, []);

  //

  return (
    <div>
      <div>
        {Authorization("Inventory", sessionStorage.get("rbac_Inventory"), [
          "Administrator",
          "InventoryManager",
        ]) ? (
          <Button
            onClick={(event) => {
              handleOpenNewNonFixedProductModal();
              HandleGetSuppliers(event);
              // HandleGetWarehouses(event);
              // HandleGetProductCategories(event);
            }}
          >
            New Non-Fixed Product
          </Button>
        ) : // <div className="AppCard">Request The Admin for Log Modul</div>
        null}
      </div>

      {/* material ui table */}
      {rows.length == 0 && <Skeleton />}
      {rows.length > 0 && (
        <Box sx={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            getRowId={(row) => row.id}
            pageSizeOptions={[5]}
            checkboxSelection
            disableRowSelectionOnClick
          />
        </Box>
      )}
      {/* <Box sx={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          getRowId={(row) => row.id}
          pageSizeOptions={[5]}
          checkboxSelection
          disableRowSelectionOnClick
        />
      </Box> */}

      {/* New Non-Fixed Product Modal */}
      <Modal
        open={openNewNonFixedProductModal}
        onClose={handleCloseNewNonFixedProductModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={newNonFixedProductStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            New Non-Fixed Product Form
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <form onSubmit={handleAddProduct}>
              <label className="productlabels">
                Product Name:
                <input
                  type="text"
                  placeholder="Parts, metals, consumables, wires….."
                  onChange={(event) => setNewProductName(event.target.value)}
                  minLength={2}
                  maxLength={100}
                  required
                ></input>
              </label>

              <label className="productlabels">
                Brand Name:
                <input
                  type="text"
                  placeholder="Ustunel"
                  onChange={(event) =>
                    setNewProductBrandName(event.target.value)
                  }
                  minLength={2}
                  maxLength={100}
                ></input>
              </label>

              <label className="productlabels">
                Internal Reference:
                <input
                  type="text"
                  placeholder="01_01_model_brand"
                  onChange={(event) =>
                    setNewProductInternalRef(event.target.value)
                  }
                  minLength={2}
                  maxLength={100}
                ></input>
              </label>

              <label className="productlabels">
                Quantity
                <input
                  type="number"
                  placeholder="1"
                  onChange={(event) =>
                    setNewProductQuantity(event.target.value)
                  }
                  min={1}
                ></input>
              </label>

              <label className="productlabels">
                Cost Price
                <input
                  type="number"
                  placeholder="100000"
                  onChange={(event) =>
                    setNewProductCostPrice(event.target.value)
                  }
                  min={1}
                ></input>
              </label>

              <label className="productlabels">
                Date of Purchase
                <input
                  type="date"
                  placeholder="Ustunel"
                  onChange={(event) =>
                    setNewProductDateofPurchase(event.target.value)
                  }
                  min={1}
                ></input>
              </label>

              <label className="productlabels">
                Product Supplier:
                <select
                  multiple={false}
                  onChange={(event) =>
                    setNewProductSupplierID(event.target.value)
                  }
                  required
                >
                  <option value="">Select a supplier</option>
                  {ProductSupplierA.map((supplier: any) => (
                    <option key={supplier.id} value={supplier.id}>
                      {supplier.name}
                    </option>
                  ))}
                </select>
              </label>

              <button className="addbtn" type="submit">
                Add Product
              </button>
            </form>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default NONFIXED;
